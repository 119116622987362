import axios from "axios";

import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from "./types";

// CHECK TOKEN & LOAD USER
export const loadUser = (userType = "ambassador") => (dispatch, getState) => {
  // User Loading
  dispatch({ type: USER_LOADING });
  if (localStorage.getItem("userId")) {
    axios
      .post(
        "http://127.0.0.1:5500/" +
          userType +
          "/" +
          localStorage.getItem("userId"),
        null,
        tokenConfig()
      )
      .then((res) => {
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: AUTH_ERROR,
        });
      });
  }
};

// LOGIN USER
export const login = (PersonalEmail, Password, userType = "ambassador") => (
  dispatch
) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({
    PersonalEmail: PersonalEmail,
    Password: Password,
  });
  axios
    .post("http://127.0.0.1:5500/" + userType + "/login", body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      userType = "advertiser";
      axios
        .post("http://127.0.0.1:5500/" + userType + "/login", body, config)
        .then((res) => {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: LOGIN_FAIL,
          });
        });
    });
};

// REGISTER USER
export const register = (user, userType = "ambassador") => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ ...user, userType: userType });

  axios
    .post("http://127.0.0.1:5500/" + userType + "/signup", body, config)
    .then((res) => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: REGISTER_FAIL,
      });
    });
};

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  dispatch({ type: "CLEAR_LEADS" });
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};

// LOGIN USER
export const contact = (PersonalEmail, Password, userType = "ambassador") => (
  dispatch
) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({
    PersonalEmail: PersonalEmail,
    Password: Password,
  });
  axios
    .post("http://127.0.0.1:5500/" + userType + "/login", body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      userType = "advertiser";
      axios
        .post("http://127.0.0.1:5500/" + userType + "/login", body, config)
        .then((res) => {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: LOGIN_FAIL,
          });
        });
    });
};

// Setup config with token - helper function
export const tokenConfig = () => {
  // Get token from state
  if (localStorage.getItem("token")) {
    const token = localStorage.getItem("token");

    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // If token, add to headers config
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  }
};

import axios from "axios";

/* export const SliderAddAction = questions => ({
  type: ADD_QUESTIONS,
  payload: questions
}); */
const questionRequest = [];
export function SliderAddAction(questions) {
  Object.keys(questions).forEach(function (key) {
    console.log(key);
    let value = { propName: key, value: questions[key] };
    questionRequest.push(value);
  });
  axios
    .post(
      "http://127.0.0.1:5500/ambassador/Questions/" +
        localStorage.getItem("userId"),
      questionRequest,
      tokenConfig()
    )
    .then((res) => {
      /*   dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data
        }); */
      console.log(res);
    })
    .catch((err) => {
      /*  dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({
          type: REGISTER_FAIL
        }); */
      console.log("ERROR " + err);
    });
}

export const tokenConfig = () => {
  // Get token from state
  if (localStorage.getItem("token")) {
    const token = localStorage.getItem("token");
    console.log("hey");
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // If token, add to headers config
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    console.log(config);
    return config;
  }
};

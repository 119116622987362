import React, { Fragment } from "react";
/* import Form from "./Form"; */
import SliderQuestions from "../layout/SliderQuestions";

export default function Dashboard() {
  return (
    <Fragment>
      {/* <Form />
      <Campaign /> */}
      <SliderQuestions />
    </Fragment>
  );
}

export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const GET_ERRORS = "GET_ERRORS";
export const GET_MESSAGES = "GET_MESSAGES";
export const CREATE_MESSAGES = "CREATE_MESSAGES";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const CLEAR_LEADS = "CLEAR_LEADS";
export const SUBMIT_QUESTION = "SUBMIT_QUESTION";
export const ADD_QUESTIONS = "ADD_QUESTIONS";

import React, { Component } from "react";
import { Link } from "react-router-dom";
export class AdvertiserAccueil extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opaimg1: "1",
      opaimg2: "1",
      opaimg3: "1",
      opaimg4: "1",
      opatxt1: "0",
      opatxt2: "0",
      opatxt3: "0",
      opatxt4: "0",
      opatxthab1: "1",
      opatxthab2: "1",
      opatxthab3: "1",
      opaimghab1: "0",
      opaimghab2: "0",
      opaimghab3: "0",
    };
  }
  showtxt = (nb) => {
    if (nb === 1) {
      this.setState({
        ...this.state,
        opaimg1: "0",
        opatxt1: "1",
        opaimghab1: "0",
        opatxthab1: "1",
      });
    } else if (nb === 2) {
      this.setState({
        ...this.state,
        opaimg2: "0",
        opatxt2: "1",
        opaimghab2: "0",
        opatxthab2: "1",
      });
    } else if (nb === 3) {
      this.setState({
        ...this.state,
        opaimg3: "0",
        opatxt3: "1",
        opaimghab3: "0",
        opatxthab3: "1",
      });
    } else if (nb === 4) {
      this.setState({
        ...this.state,
        opaimg4: "0",
        opatxt4: "1",
        opaimghab4: "0",
        opatxthab4: "1",
      });
    }
  };

  hidetxt = (nb) => {
    if (nb === 1) {
      this.setState({
        ...this.state,
        opaimg1: "1",
        opatxt1: "0",
        opaimghab1: "1",
        opatxthab1: "0",
      });
    } else if (nb === 2) {
      this.setState({
        ...this.state,
        opaimg2: "1",
        opatxt2: "0",
        opaimghab2: "1",
        opatxthab2: "0",
      });
    } else if (nb === 3) {
      this.setState({
        ...this.state,
        opaimg3: "1",
        opatxt3: "0",
        opaimghab3: "1",
        opatxthab3: "0",
      });
    } else if (nb === 4) {
      this.setState({
        ...this.state,
        opaimg4: "1",
        opatxt4: "0",
        opaimghab4: "1",
        opatxthab4: "0",
      });
    }
  };

  render() {
    return (
      <div
        style={{ marginTop: "1em" }}
        className=" col-lg-12 col-sm-12  ml-auto mr-auto"
      >
        <div
          id="PictureTitle"
          className="col-md-8 col-lg-8 col-sm-8 ml-auto mr-auto"
          style={{ display: "flex" }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "10%",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                textAlign: "center",
                fontFamily: "Montserrat Bold 40px/49px ",
                fontWeight: "Bold",
                letterSpacing: "-1px",
                color: "#012C3D",
                fontSize: "2.5em",
                width: "95%",
                height: " 147px",
                lineHeight: "110%",
              }}
            >
              Offrez à vos marques une publicité innovante
            </span>
            <img
              alt="advertiserAcceuil"
              src="/advertiseracceuil.jpg"
              style={{
                height: "20em",
                width: "80%",

                marginTop: "-6%",
                marginRight: "2em",
              }}
            />
            <Link
              to={{
                pathname: "/register",
                state: {
                  SelectableUser: "ClickAnnonceur",
                },
              }}
              style={{
                backgroundColor: "#EB2735",
                borderColor: "#EB2735",
                color: "#ffffff",
                fontFamily: " Montserrat",
                fontWeight: "600",
                fontSize: "1.2em",
                borderTopRightRadius: "25px",
                borderBottomRightRadius: "25px",
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                paddingLeft: "3.5%",
                paddingRight: "3.5%",
                paddingTop: "0.8%",
                paddingBottom: "0.8%",
                textAlign: "center",
              }}
            >
              Nous Rejoindre
            </Link>
          </div>
        </div>
        <div
          id="HowItWorks"
          className="mt-5 col-md-9 col-lg-9 col-sm-9 ml-auto mr-auto"
          style={{
            backgroundColor: "#78BDC4",
            borderRadius: "31px",
            zIndex: "2",
            position: "relative",
          }}
        >
          <div>
            <h1
              className="w-100 Fsize14"
              style={{
                textAlign: "center",
                paddingTop: "5%",
                color: "#ffffff",
                fontFamily: " Montserrat",
                fontWeight: "600",
              }}
            >
              Comment ça marche ?
            </h1>
          </div>
          <div
            style={{
              flex: "auto",
              display: "flex",
              justifyContent: "space-around",
              paddingBottom: "7%",
            }}
            className="Dblock"
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1em",
                }}
              >
                <span style={{ fontSize: "1.7em", font: "Poppins" }}>1.</span>{" "}
                Inscription
              </div>

              <img
                alt="computer"
                src="/computer.png"
                style={{
                  marginTop: "10%",
                  opacity: this.state.opaimg1,
                  transition: "all 100ms ease-out",
                  width: "40%",
                  height: "6em",
                }}
                className="mt2em"
              />
              <div
                className="content w80Mt5em"
                onMouseEnter={() => this.showtxt(1)}
                onMouseLeave={() => this.hidetxt(1)}
                style={{
                  position: "absolute",
                  marginTop: "6%",
                  opacity: this.state.opatxt1,
                  transition: "all 100ms ease-in",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.85em",
                  width: "18.4%",
                  cursor: "default",
                  height: "30%",
                }}
              >
                <div className="text">
                  Inscrivez vous sur la plateforme. Nous vous appellerons pour
                  vous aider à créer la campagne qui correspond à vos objectifs.
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1em",
                }}
              >
                <span style={{ fontSize: "1.7em", font: "Poppins" }}>2.</span>{" "}
                Objectifs de la compagne
              </div>

              <img
                alt="engineering"
                src="/engineering.png"
                style={{
                  marginTop: "10%",
                  opacity: this.state.opaimg2,
                  transition: "all 100ms ease-out",
                  width: "40%",
                  height: "6em",
                }}
                className="mt2em"
              />
              <div
                onMouseEnter={() => this.showtxt(2)}
                onMouseLeave={() => this.hidetxt(2)}
                className="content w80Mt5em"
                style={{
                  position: "absolute",

                  marginTop: "6%",
                  opacity: this.state.opatxt2,
                  transition: "all 100ms ease-in",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.85em",
                  width: "18.4%",
                  cursor: "default",
                  height: "30%",
                }}
              >
                <div className="text">
                  Vous pouvez définir les objectifs de votre campagne en
                  choisissant votre zone cible, votre budget, le nombre de
                  voitures...
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                }}
              >
                <span style={{ fontSize: "1.7em", font: "Poppins" }}>3.</span>{" "}
                Choix des ambassadeurs
              </div>

              <img
                alt="choice"
                src="/choice.png"
                style={{
                  marginTop: "10%",
                  opacity: this.state.opaimg3,
                  transition: "all 100ms ease-out",
                  width: "40%",
                  height: "6em",
                }}
                className="mt2em"
              />
              <div
                onMouseEnter={() => this.showtxt(3)}
                onMouseLeave={() => this.hidetxt(3)}
                className="content w80Mt5em"
                style={{
                  position: "absolute",

                  marginTop: "6%",
                  opacity: this.state.opatxt3,
                  transition: "all 100ms ease-in",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.85em",
                  width: "18.4%",
                  cursor: "default",
                  height: "30%",
                }}
              >
                <div className="text">
                  Nous vous proposerons les ambassadeurs qui correspondent le
                  mieux aux objectifs de votre campagne.
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                }}
              >
                <span style={{ fontSize: "1.7em", font: "Poppins" }}> 4.</span>{" "}
                Suivi de la campagne
              </div>

              <img
                alt="Page-1"
                src="/Page-1.png"
                style={{
                  marginTop: "10%",
                  opacity: this.state.opaimg4,
                  transition: "all 100ms ease-out",
                  width: "40%",
                  height: "6em",
                }}
                className="mt2em"
              />
              <div
                onMouseEnter={() => this.showtxt(4)}
                onMouseLeave={() => this.hidetxt(4)}
                className="content w80Mt5em"
                style={{
                  position: "absolute",

                  marginTop: "6%",
                  opacity: this.state.opatxt4,
                  transition: "all 100ms ease-in",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.85em",
                  width: "18.4%",
                  cursor: "default",
                  height: "30%",
                }}
              >
                <div className="text">
                  Grâce au tableau de bord de la plateforme, vous pouvez suivre
                  votre campagne en temps réel.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="Details"
          className="col-md-12 col-xl-12 col-sm-12 ml-auto mr-auto"
          style={{
            backgroundColor: "#e5e9ec",
            borderRadius: "31px",

            marginTop: "-5%",
            zIndex: "1",
            position: "relative",
            paddingBottom: "5em",
          }}
        >
          <div
            id="detailsTitle"
            style={{
              margin: "auto",
              top: "4em",
              position: " relative",
              width: "100%",
              height: "8em",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontWeight: "700",
              letterSpacing: "0px",
              fontSize: " 30px",
              color: " #012C3D",
            }}
          >
            Ciblez , Afficher , Suivez !
          </div>
          <div
            id="detailsContent"
            className="Dblock"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              id="detailContentLeft"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "25%",
              }}
              className="w100percent"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10em",
                  width: "10em",
                  backgroundColor: "#012C3D",
                  borderRadius: "50%",
                }}
              >
                <img alt="wallet-bank" src="/wallet.png" />
              </div>
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                  fontSize: "20px",
                  marginTop: "1em",
                  width: "100%",
                }}
              >
                Petit prix
              </div>
              <div
                style={{
                  position: " relative",
                  width: "50%",
                  height: "8em",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  color: " #000000",
                }}
              >
                Un coût abordable pour un nombre d'impression élevé.
              </div>
              <div
                style={{
                  height: "10em",
                  width: "10em",
                  backgroundColor: "#012C3D",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img alt="sway" src="/sway.png" />
              </div>
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                  fontSize: "20px",

                  marginTop: "1em",
                  width: "100%",
                }}
              >
                Ciblage précis
              </div>
              <div
                style={{
                  position: " relative",
                  width: "50%",
                  height: "8em",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  color: " #000000",
                }}
              >
                Choisir les ambassadeurs en fonction de leurs zone, âge,
                préférence…
              </div>
            </div>
            <div
              id="detailContentCenter"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <img
                  alt="advertiser"
                  src="/advertiser.jpg"
                  style={{ width: "100%", height: "100%" }}
                  className="DNonetxt"
                />
              </div>
              <div
                style={{
                  position: " relative",
                  borderRadius: "10px",
                  border: "3px solid",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  letterSpacing: "0px",
                  fontSize: "25px",
                  marginTop: "3.2em",
                  width: "17.5em",
                  paddingBottom: "0.3em",
                  paddingTop: "0.3em",
                  paddingLeft: "1em",
                  paddingRight: "1em",
                  color: " #78BDC4",
                }}
                className="Fsize1 mt0em"
              >
                Digitaliser l'affichage urbain
              </div>
            </div>
            <div
              id="detailContentRight"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "25%",
              }}
              className="w100percent mt3em"
            >
              <div
                style={{
                  height: "10em",
                  width: "10em",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#012C3D",
                }}
              >
                <img alt="Web analytics" src="/Web analytics.png" />
              </div>
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                  fontSize: "20px",
                  marginTop: "1em",
                }}
              >
                Suivi des campagnes
              </div>
              <div
                style={{
                  position: " relative",
                  width: "50%",
                  height: "8em",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  color: " #000000",
                }}
              >
                Suivre le nombre d'impression, la position des voitures, le
                nombre de km parcouru ….
              </div>
              <div
                style={{
                  backgroundColor: "#012C3D",
                  height: "10em",
                  width: "10em",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img alt="placeholder" src="/placeholder.png" />
              </div>
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                  fontSize: "20px",

                  marginTop: "1em",
                }}
              >
                Clients engagés
              </div>
              <div
                style={{
                  position: " relative",
                  width: "50%",
                  height: "8em",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  color: " #000000",
                }}
              >
                Les ambassadeurs représentent la marque qu'ils choisissent.
              </div>
            </div>
          </div>
        </div>
        <div
          id="TypeHabillage"
          style={{
            backgroundColor: "#012C3D",
            borderRadius: "31px",
            zIndex: "2",
            position: "relative",
            marginTop: "-3em",
          }}
          className="col-md-9 col-lg-9 col-sm-9 ml-auto mr-auto"
        >
          <div>
            <h1
              className="w-100 Fsize2"
              style={{
                textAlign: "center",
                paddingTop: "5%",
                color: "#ffffff",
              }}
            >
              Type d'habillage
            </h1>
          </div>
          <div
            style={{
              flex: "auto",
              display: "flex",
              justifyContent: "space-evenly",
              paddingBottom: "7%",
            }}
            className="Dblock"
          >
            <div
              onMouseEnter={() => this.hidetxt(1)}
              onMouseLeave={() => this.showtxt(1)}
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "700",
                  letterSpacing: "0px",
                  position: "absolute",
                  fontSize: "30px",
                  opacity: this.state.opatxthab1,
                  transition: "all 100ms ease-out",
                }}
              >
                Stickers
              </div>

              <img
                alt="stickers"
                src="/stickers.jpg"
                style={{
                  opacity: this.state.opaimghab1,
                  transition: "all 100ms ease-in",
                  height: "70%",
                }}
              />
              {/*  */}
            </div>
            <div
              onMouseEnter={() => this.hidetxt(2)}
              onMouseLeave={() => this.showtxt(2)}
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "700",
                  letterSpacing: "0px",
                  position: "absolute",
                  fontSize: "30px",

                  opacity: this.state.opatxthab2,
                  transition: "all 100ms ease-out",
                }}
              >
                Partiel
              </div>

              <img
                alt="stickers"
                src="/partiel.jpg"
                style={{
                  opacity: this.state.opaimghab2,
                  transition: "all 100ms ease-in",
                  height: "70%",
                }}
              />
              {/*  */}
            </div>
            <div
              onMouseEnter={() => this.hidetxt(3)}
              onMouseLeave={() => this.showtxt(3)}
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "700",
                  letterSpacing: "0px",
                  position: "absolute",
                  fontSize: "30px",

                  opacity: this.state.opatxthab3,
                  transition: "all 100ms ease-out",
                }}
              >
                Total
              </div>

              <img
                alt="total"
                src="/total.jpg"
                style={{
                  opacity: this.state.opaimghab3,
                  transition: "all 100ms ease-in",
                  height: "70%",
                }}
              />
              {/*  */}
            </div>
          </div>
        </div>
        <div
          id="Details"
          style={{
            backgroundColor: "#e5e9ec",
            borderRadius: "31px",

            marginTop: "-5%",
            zIndex: "1",
            position: "relative",
            height: "fit-content",
          }}
        >
          <div
            id="detailsTitle"
            style={{
              margin: "auto",
              top: "1.5em",
              position: " relative",
              width: "100%",
              height: "7em",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontWeight: "700",
              letterSpacing: "0px",
              fontSize: " 30px",
              color: " #EB2735",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "#B7B7B7",
                opacity: "40%",
                fontFamily: "Montserrat",
                fontWeight: "800",
                position: "absolute",
                fontSize: "2.2em",
              }}
            >
              FAQ
            </span>
            <span> QUESTION / REPONSE</span>
          </div>
          <div
            id="detailsContent"
            className="col-md-8 col-lg-8 col-sm-8 ml-auto mr-auto "
            style={{
              display: "flex",
              margin: "auto",
              flexDirection: "column",
              paddingBottom: "5em",
            }}
          >
            <div
              className="w-100"
              style={{
                backgroundColor: "#FFFFFF",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                1. Winوين, c'est quoi ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Winوين est une plateforme qui propose aux marques un moyen de
                communication innovant, consistant à poser sur des véhicules de
                particuliers des stickers publicitaires .
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                2. Dans quel cas utiliser Winوين ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Pour faire la publicité d'une nouvelle marque, d'un nouveau
                produit ou promouvoir une marque.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                3. Est ce que je peux choisir les ambassadeurs qui vont
                représenter ma marque ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Oui, le choix des ambassadeurs se fait en fonction des critères
                de votre campagne (zone, âge, centre d'intérêt..).
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                4. Est ce que je peux choisir le design de l'habillage ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Oui bien sur, vous pouvez nous fournir les fichiers de vos
                habillages ou bien nous pouvons le faire pour vous.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                5. Combien dure une campagne ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Une campagne dure entre 15 jours et 3 mois.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                6. Est ce qu'il y a un nombre maximum de voitures ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Le nombre de voitures dépend de vos objectifs et de votre
                budget.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                7. Le nombre de voitures dépend de vos objectifs et de votre
                budget.
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Nous nous chargerons de le remplacer par un autre avec les mêmes
                critères.
              </p>
            </div>
          </div>
        </div>

        <div id="QuestionAnswers"></div>
      </div>
    );
  }
}

export default AdvertiserAccueil;

import React, { useState /* setState */ } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
/* import DatePicker from "react-datepicker"; */
import { connect } from "react-redux";
import { SliderAddAction } from "../../Redux/actions/SliderQuestions";
/* import PropTypes from "prop-types"; */
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { registerLocale } from "react-datepicker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
registerLocale("fr", fr);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    backgroundColor: "#EB2735",
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
let lastday;
let dayThirty;
let dayTwentyNine;
function getSteps() {
  return [
    "Informations générales",
    "Informations sur le véhicule ",
    "Information photographique",
  ];
}

const CurrentYear = new Date().getFullYear();
function StepperComponent() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  /*   const [startDate, setStartDate] = useState(new Date()); */
  const [state, setState] = React.useState({
    villeR: "Ariana",
    ZoneR: "Ariana ville",
    villeW: "Ariana",
    ZoneW: "Ariana ville",
    DayOfBirth: 1,
    MonthOfBirth: 2,
    YearOfBirth: CurrentYear - 18,
    ProfStatus: "",
    matriStatus: "",
    NbrKids: 0,
    HeardOfUs: "",
    Intrest: [],
    NbrKm: "",
    CarModel: "",
    CarMarque: "",
    Color: "Red",
    YearFabrication: CurrentYear,
    insuranceCompany: "",
    stationPlace: "",
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  if (parseInt(state.MonthOfBirth, 10) === 2 && state.YearOfBirth % 4 === 0) {
    dayThirty = null;
    dayTwentyNine = (
      <option key={29} value={29}>
        29
      </option>
    );
  } else if (
    parseInt(state.MonthOfBirth, 10) === 2 &&
    parseInt(state.YearOfBirth, 10) % 4 !== 0
  ) {
    dayThirty = null;
    dayTwentyNine = null;
  } else {
    dayThirty = (
      <option key={30} value={30}>
        30
      </option>
    );
    dayTwentyNine = (
      <option key={29} value={29}>
        29
      </option>
    );
  }
  console.log(typeof parseInt(state.MonthOfBirth, 10));
  if (
    parseInt(state.MonthOfBirth, 10) === 1 ||
    parseInt(state.MonthOfBirth, 10) === 3 ||
    parseInt(state.MonthOfBirth, 10) === 5 ||
    parseInt(state.MonthOfBirth, 10) === 7 ||
    parseInt(state.MonthOfBirth, 10) === 8 ||
    parseInt(state.MonthOfBirth, 10) === 10 ||
    parseInt(state.MonthOfBirth, 10) === 12
  ) {
    lastday = (
      <option key={31} value={31}>
        31
      </option>
    );
  } else {
    lastday = null;
  }
  const names = [
    "Sport",
    "Musique",
    "Voyage",
    "Cuisine",
    "Cinéma",
    "Beauté",
    "High Tech",
    "Manga",
    "Photographie",
  ];
  const PeugeotModel = [
    "106",
    "206",
    "207",
    "208",
    "306",
    "307",
    "308",
    "407",
    "406",
    "508",
    "2008",
    "3008",
  ];
  const ReneautModel = [
    "Clio",
    "Clio classique",
    "Symbol",
    "Captur",
    "Megane sedan",
    "Kadjar",
    "Partner",
    "Talisman",
  ];
  const AudiModel = [
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "Q2",
    "Q3",
    "Q4",
    "Q5",
    "Q6",
    "Q7",
    "Q8",
  ];
  const CitroenModel = ["C3", "C4", "C5"];
  const BmwModel = [
    "Serie 1",
    "Serie 2",
    "Serie 3",
    "Serie 4",
    "Serie 5",
    "X1",
    "X2",
    "X3",
  ];
  const MercedesModel = [
    "Classe A",
    "Classe C",
    "Classe E",
    "CLA",
    "GLA",
    "GLC",
  ];
  const VolkswagenModel = [
    "Golf 4",
    "Golf 5",
    "Golf 6",
    "Golf 7",
    "Polo 4",
    "Polo 5",
    "Polo 6",
    "Polo 7",
    "Polo Sedan",
    "UP",
    "Passat",
  ];
  const SuzukiModel = ["Celerio", "Dzire", "Swift", "Baleno", "Ciaz"];
  const SeatModel = ["Ibiza", "Leon", "Arona"];
  const ChevroletModel = ["Sonic"];
  const ToyotaModel = [
    "Yaris",
    "Yaris Sedan",
    "Corolla",
    "Corolla Sedan",
    "C-HR",
  ];
  const FordModel = ["Fiesta", "Ecosport", "Kuga", "Fusion", "Ranger", "Focus"];
  const FiatModel = [
    "Panda",
    "Punto",
    "500",
    "Tipo",
    "500x",
    "Qubo",
    "Fiorino",
    "Doblo",
  ];
  const MitsubishiModel = [
    "SAM-Mirage",
    "SAM-Attrage",
    "SAM-ASX",
    "SAM-Pajero",
  ];
  const KiaModel = ["Picanto", "Rio 5p", "Rio Berline", "Sportage"];
  const HyundaiModel = [
    "Grand I10",
    "Grand I10 Sedan",
    "I20",
    "Elantra",
    "Creta",
    "Tucson",
    "Grand Santa FE",
  ];
  const HondaModel = ["City", "HR-V", "Civic", "Accord", "CR-V"];
  const MazdaModel = [
    "Mazda 2",
    "Mazda 2 Sedan",
    "Mazda CX-3",
    "Mazda CX-5",
    "Mazda CX-9",
  ];
  const OpelModel = ["Astra", "Corsa", "Corsa 3P", "Insignia", "Meriva"];

  const CarYears = [];
  for (let i = 0; i < 11; i++) {
    CarYears.push(CurrentYear - i);
  }
  const Years = [];
  for (let i = 0; i < 60; i++) {
    Years.push(CurrentYear - i - 18);
  }
  const steps = getSteps();
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <form className="col-xl-9 col-md-12 col-sm-12 m-auto">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid>
                <div
                  className="col-xl-6 col-md-12 col-sm-12"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Date de naissance
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "33%" }}
                    value={state.DayOfBirth}
                    onChange={handleChange("DayOfBirth")}
                    inputProps={{
                      name: "DayOfBirth",
                      id: "DayOfBirth-native-simple",
                    }}
                  >
                    <option key={1} value={1}>
                      01
                    </option>
                    <option key={2} value={2}>
                      02
                    </option>
                    <option key={3} value={3}>
                      03
                    </option>
                    <option key={4} value={4}>
                      04
                    </option>
                    <option key={5} value={5}>
                      05
                    </option>
                    <option key={6} value={6}>
                      06
                    </option>
                    <option key={7} value={7}>
                      07
                    </option>
                    <option key={8} value={8}>
                      08
                    </option>
                    <option key={9} value={9}>
                      09
                    </option>
                    <option key={10} value={10}>
                      10
                    </option>
                    <option key={11} value={11}>
                      11
                    </option>
                    <option key={12} value={12}>
                      12
                    </option>
                    <option key={13} value={13}>
                      13
                    </option>
                    <option key={14} value={14}>
                      14
                    </option>
                    <option key={15} value={15}>
                      15
                    </option>
                    <option key={16} value={16}>
                      16
                    </option>
                    <option key={17} value={17}>
                      17
                    </option>
                    <option key={18} value={18}>
                      18
                    </option>
                    <option key={19} value={19}>
                      19
                    </option>
                    <option key={20} value={20}>
                      20
                    </option>
                    <option key={21} value={21}>
                      21
                    </option>
                    <option key={22} value={22}>
                      22
                    </option>
                    <option key={23} value={23}>
                      23
                    </option>
                    <option key={24} value={24}>
                      24
                    </option>
                    <option key={25} value={25}>
                      25
                    </option>
                    <option key={26} value={26}>
                      26
                    </option>
                    <option key={27} value={27}>
                      27
                    </option>
                    <option key={28} value={28}>
                      28
                    </option>
                    {dayTwentyNine}
                    {dayThirty}
                    {lastday}
                  </select>

                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "33%" }}
                    value={parseInt(state.MonthOfBirth, 10)}
                    onChange={handleChange("MonthOfBirth")}
                    inputProps={{
                      name: "MonthOfBirth",
                      id: "MonthOfBirth-native-simple",
                    }}
                  >
                    <option key={1} value={1}>
                      01
                    </option>
                    <option key={2} value={2}>
                      02
                    </option>
                    <option key={3} value={3}>
                      03
                    </option>
                    <option key={4} value={4}>
                      04
                    </option>
                    <option key={5} value={5}>
                      05
                    </option>
                    <option key={6} value={6}>
                      06
                    </option>
                    <option key={7} value={7}>
                      07
                    </option>
                    <option key={8} value={8}>
                      08
                    </option>
                    <option key={9} value={9}>
                      09
                    </option>
                    <option key={10} value={10}>
                      10
                    </option>
                    <option key={11} value={11}>
                      11
                    </option>
                    <option key={12} value={12}>
                      12
                    </option>
                  </select>

                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "33%" }}
                    value={state.YearOfBirth}
                    onChange={handleChange("YearOfBirth")}
                    inputProps={{
                      name: "YearOfBirth",
                      id: "YearOfBirth-native-simple",
                    }}
                  >
                    {Years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Lieu de résidence
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "50%" }}
                    value={state.villeR}
                    onChange={handleChange("villeR")}
                    inputProps={{
                      name: "villeR",
                      id: "villeR-native-simple",
                    }}
                  >
                    <option key={""} value="" />
                    <option key={"Ariana"} value={"Ariana"}>
                      Ariana
                    </option>
                    <option key={"Tunis"} value={"Tunis"}>
                      Tunis
                    </option>
                  </select>
                  {state.villeR === "Ariana" ? (
                    <select
                      className="WinwinInput"
                      native
                      style={{ width: "50%" }}
                      value={state.ZoneR}
                      onChange={handleChange("ZoneR")}
                      inputProps={{
                        name: "ZoneR",
                        id: "ZoneR-native-simple",
                      }}
                    >
                      <option key={""} value="" />
                      <option key={"ArianaVille"} value={"Ariana ville"}>
                        Ariana ville
                      </option>
                      <option key={"Ettadhamen"} value={"Ettadhamen"}>
                        Ettadhamen
                      </option>
                      <option key={"KalaatLandlous"} value={"Kalaat Landlous"}>
                        Kalaat Landlous
                      </option>
                      <option key={"Soukra"} value={"Soukra"}>
                        Soukra
                      </option>
                      <option key={"Mnihla"} value={"Mnihla"}>
                        Mnihla
                      </option>
                      <option key={"Raoued"} value={"Raoued"}>
                        Raoued
                      </option>
                      <option key={"Ghazela"} value={"Ghazela"}>
                        Cité Ghazela
                      </option>
                      <option key={"BorjLouzir"} value={"Borj Louzir"}>
                        Borj Louzir
                      </option>
                    </select>
                  ) : state.villeR === "Tunis" ? (
                    <select
                      className="WinwinInput"
                      native
                      style={{ width: "50%" }}
                      value={state.ZoneR}
                      onChange={handleChange("ZoneR")}
                      inputProps={{
                        name: "ZoneR",
                        id: "ZoneR-native-simple",
                      }}
                    >
                      <option key={""} value="" />
                      <option key={"CentreVille"} value={"Centre Ville"}>
                        Centre Ville
                      </option>
                      <option key={"Marsa"} value={"Marsa"}>
                        Marsa
                      </option>
                      <option key={"Montplaisir"} value={"Montplaisir"}>
                        Montplaisir
                      </option>
                      <option key={"MutuelleVille"} value={"Mutuelle ville"}>
                        Mutuelle ville
                      </option>
                      <option key={"Carthage"} value={"Carthage"}>
                        Carthage
                      </option>
                      <option key={"Gammarth"} value={"Gammarth"}>
                        Gammarth
                      </option>
                      <option key={"Bardo"} value={"Bardo"}>
                        Bardo
                      </option>
                    </select>
                  ) : (
                    <select
                      className="WinwinInput"
                      native
                      style={{ width: "50%" }}
                      value={state.ZoneR}
                      onChange={handleChange("ZoneR")}
                      inputProps={{
                        name: "ZoneR",
                        id: "ZoneR-native-simple",
                      }}
                    >
                      <option key={""} value="" />
                    </select>
                  )}
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Lieu de travail ou d'études
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "50%" }}
                    value={state.villeW}
                    onChange={handleChange("villeW")}
                    inputProps={{
                      name: "villeW",
                      id: "villeW-native-simple",
                    }}
                  >
                    <option key={""} value="" />
                    <option key={"Ariana"} value={"Ariana"}>
                      Ariana
                    </option>
                    <option key={"Tunis"} value={"Tunis"}>
                      Tunis
                    </option>
                  </select>
                  {state.villeW === "Ariana" ? (
                    <select
                      className="WinwinInput"
                      native
                      style={{ width: "50%" }}
                      value={state.ZoneW}
                      onChange={handleChange("ZoneW")}
                      inputProps={{
                        name: "ZoneW",
                        id: "ZoneW-native-simple",
                      }}
                    >
                      <option key={""} value="" />
                      <option key={"ArianaVille"} value={"Ariana ville"}>
                        Ariana ville
                      </option>
                      <option key={"Ettadhamen"} value={"Ettadhamen"}>
                        Ettadhamen
                      </option>
                      <option key={"KalaatLandlous"} value={"Kalaat Landlous"}>
                        Kalaat Landlous
                      </option>
                      <option key={"Soukra"} value={"Soukra"}>
                        Soukra
                      </option>
                      <option key={"Mnihla"} value={"Mnihla"}>
                        Mnihla
                      </option>
                      <option key={"Raoued"} value={"Raoued"}>
                        Raoued
                      </option>
                      <option key={"Ghazela"} value={"Ghazela"}>
                        Cité Ghazela
                      </option>
                      <option key={"BorjLouzir"} value={"Borj Louzir"}>
                        Borj Louzir
                      </option>
                    </select>
                  ) : state.villeW === "Tunis" ? (
                    <select
                      className="WinwinInput"
                      style={{ width: "50%" }}
                      native
                      value={state.ZoneW}
                      onChange={handleChange("ZoneW")}
                      inputProps={{
                        name: "ZoneW",
                        id: "ZoneW-native-simple",
                      }}
                    >
                      <option key={""} value="" />
                      <option key={"CentreVille"} value={"Centre Ville"}>
                        Centre Ville
                      </option>
                      <option key={"Marsa"} value={"Marsa"}>
                        Marsa
                      </option>
                      <option key={"Montplaisir"} value={"Montplaisir"}>
                        Montplaisir
                      </option>
                      <option key={"MutuelleVille"} value={"Mutuelle ville"}>
                        Mutuelle ville
                      </option>
                      <option key={"Carthage"} value={"Carthage"}>
                        Carthage
                      </option>
                      <option key={"Gammarth"} value={"Gammarth"}>
                        Gammarth
                      </option>
                      <option key={"Bardo"} value={"Bardo"}>
                        Bardo
                      </option>
                    </select>
                  ) : (
                    <select
                      className="WinwinInput"
                      native
                      style={{ width: "50%" }}
                      value={state.ZoneW}
                      onChange={handleChange("ZoneW")}
                      inputProps={{
                        name: "ZoneW",
                        id: "ZoneW-native-simple",
                      }}
                    >
                      <option key={""} value="" />
                    </select>
                  )}
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Situation professionnelle
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "100%" }}
                    value={state.ProfStatus}
                    onChange={handleChange("ProfStatus")}
                    inputProps={{
                      name: "ProfStatus",
                      id: "ProfStatus-native-simple",
                    }}
                  >
                    <option key={""} value={""}>
                      {""}
                    </option>
                    <option key={"Etudiant"} value={"Etudiant"}>
                      Etudiant
                    </option>
                    <option key={"salarie"} value={"salarié"}>
                      Salarié
                    </option>
                    <option
                      key={"professionLibérale"}
                      value={"profession libérale"}
                    >
                      Profession libérale
                    </option>
                    <option
                      key={"LookingForJob"}
                      value={"à la recherche d'un emploi"}
                    >
                      à la recherche d'un emploi
                    </option>
                  </select>
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Situation matrimoniale
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "100%" }}
                    value={state.matriStatus}
                    onChange={handleChange("matriStatus")}
                    inputProps={{
                      name: "matriStatus",
                      id: "matriStatus-native-simple",
                    }}
                  >
                    <option key={""} value={""}>
                      {""}
                    </option>
                    <option key={"Marié"} value={"Marié"}>
                      Marié
                    </option>
                    <option key={"célibataire"} value={"célibataire"}>
                      célibataire
                    </option>
                  </select>
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Nombre d’enfants
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "100%" }}
                    value={state.NbrKids}
                    onChange={handleChange("NbrKids")}
                    inputProps={{
                      name: "NbrKids",
                      id: "NbrKids-native-simple",
                    }}
                  >
                    <option key={""} value={""}>
                      {""}
                    </option>
                    <option key={0} value={0}>
                      0
                    </option>
                    <option key={1} value={1}>
                      1
                    </option>
                    <option key={2} value={1}>
                      2
                    </option>
                    <option key={3} value={1}>
                      3
                    </option>
                    <option key={4} value={1}>
                      4
                    </option>
                    <option key={5} value={1}>
                      5
                    </option>
                  </select>
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Centre d'intérêt
                  </label>

                  <Select
                    className="WinwinInput"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    style={{ width: "100%" }}
                    value={state.Intrest}
                    onChange={handleChange("Intrest")}
                    input={<Input />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {names.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={state.Intrest.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Comment avez-vous entendu parler de nous ?
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "100%" }}
                    value={state.HeardOfUs}
                    onChange={handleChange("HeardOfUs")}
                    inputProps={{
                      name: "HeardOfUs",
                      id: "HeardOfUs-native-simple",
                    }}
                  >
                    <option key={""} value={""}>
                      {""}
                    </option>
                    <option key={"reseauxSociaux"} value={"réseaux sociaux"}>
                      Réseaux sociaux
                    </option>
                    <option key={"entourage"} value={"entourage"}>
                      Entourage
                    </option>
                    <option key={"voiture"} value={"voiture"}>
                      Voiture
                    </option>
                  </select>
                </div>
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        );
      case 1:
        return (
          <form className="col-xl-9 col-md-12 col-sm-12 m-auto">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid>
                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Marque
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "100%" }}
                    value={state.CarMarque}
                    onChange={handleChange("CarMarque")}
                    inputProps={{
                      name: "CarMarque",
                      id: "CarMarque-native-simple",
                    }}
                  >
                    <option key={""} value={""}>
                      {""}
                    </option>
                    <option key={"Audi"} value={"Audi"}>
                      Audi
                    </option>
                    <option key={"BMW"} value={"BMW"}>
                      BMW
                    </option>
                    <option key={"Chevrolet"} value={"Chevrolet"}>
                      Chevrolet
                    </option>
                    <option key={"Citroën"} value={"Citroën"}>
                      Citroën
                    </option>
                    <option key={"Fiat"} value={"Fiat"}>
                      Fiat
                    </option>
                    <option key={"Ford"} value={"Ford"}>
                      Ford
                    </option>
                    <option key={"Honda"} value={"Honda"}>
                      Honda
                    </option>
                    <option key={"Hyundai"} value={"Hyundai"}>
                      Hyundai
                    </option>
                    <option key={"Kia"} value={"Kia"}>
                      Kia
                    </option>
                    <option key={"Mazda"} value={"Mazda"}>
                      Mazda
                    </option>
                    <option key={"Mercedes"} value={"Mercedes"}>
                      Mercedes
                    </option>
                    <option key={"Mitsubishi"} value={"Mitsubishi"}>
                      Mitsubishi
                    </option>
                    <option key={"Opel"} value={"Opel"}>
                      Opel
                    </option>
                    <option key={"Peugeot"} value={"Peugeot"}>
                      Peugeot
                    </option>
                    <option key={"Reneaut"} value={"Reneaut"}>
                      Reneaut
                    </option>
                    <option key={"Seat"} value={"Seat"}>
                      Seat
                    </option>
                    <option key={"Suzuki"} value={"Suzuki"}>
                      Suzuki
                    </option>
                    <option key={"Toyota"} value={"Toyota"}>
                      Toyota
                    </option>
                    <option key={"Volkswagen"} value={"Volkswagen"}>
                      Volkswagen
                    </option>
                  </select>
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Modèle
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "100%" }}
                    value={state.CarModel}
                    onChange={handleChange("CarModel")}
                    inputProps={{
                      name: "CarModel",
                      id: "CarModel-native-simple",
                    }}
                  >
                    <option key={""} value={""}>
                      {""}
                    </option>
                    {state.CarMarque === "Peugeot"
                      ? PeugeotModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Audi"
                      ? AudiModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "BMW"
                      ? BmwModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Chevrolet"
                      ? ChevroletModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Citroën"
                      ? CitroenModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Fiat"
                      ? FiatModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Ford"
                      ? FordModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Honda"
                      ? HondaModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Hyundai"
                      ? HyundaiModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Mitsubishi"
                      ? MitsubishiModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Mercedes"
                      ? MercedesModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Opel"
                      ? OpelModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Reneaut"
                      ? ReneautModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Seat"
                      ? SeatModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Mazda"
                      ? MazdaModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Suzuki"
                      ? SuzukiModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Toyota"
                      ? ToyotaModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Volkswagen"
                      ? VolkswagenModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Mazda"
                      ? MazdaModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : state.CarMarque === "Kia"
                      ? KiaModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))
                      : AudiModel.map((car) => (
                          <option key={state.CarMarque + car} value={car}>
                            {car}
                          </option>
                        ))}
                  </select>
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Année de fabrication
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "100%" }}
                    value={state.YearFabrication}
                    onChange={handleChange("YearFabrication")}
                    inputProps={{
                      name: "YearFabrication",
                      id: "YearFabrication-native-simple",
                    }}
                  >
                    <option key={""} value={""}>
                      {""}
                    </option>
                    {CarYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Couleur
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "100%", backgroundColor: state.Color }}
                    value={state.Color}
                    onChange={handleChange("Color")}
                    inputProps={{
                      name: "Color",
                      id: "Color-native-simple",
                    }}
                  >
                    <option
                      key={"Red"}
                      value={"Red"}
                      style={{ backgroundColor: "Red" }}
                    >
                      {" "}
                    </option>
                    <option
                      key={"Blue"}
                      value={"Blue"}
                      style={{ backgroundColor: "Blue" }}
                    >
                      {" "}
                    </option>
                    <option
                      key={"Green"}
                      value={"Green"}
                      style={{ backgroundColor: "Green" }}
                    >
                      {" "}
                    </option>
                    <option
                      key={"Yellow"}
                      value={"Yellow"}
                      style={{ backgroundColor: "Yellow" }}
                    >
                      {" "}
                    </option>
                    <option
                      key={"Black"}
                      value={"Black"}
                      style={{ backgroundColor: "Black" }}
                    >
                      {" "}
                    </option>
                    <option
                      key={"Grey"}
                      value={"Grey"}
                      style={{ backgroundColor: "Grey" }}
                    >
                      {" "}
                    </option>
                    <option
                      key={"Brown"}
                      value={"Brown"}
                      style={{ backgroundColor: "Brown" }}
                    >
                      {" "}
                    </option>
                    <option
                      key={"White"}
                      value={"White"}
                      style={{ backgroundColor: "White" }}
                    >
                      {" "}
                    </option>
                    <option
                      key={"Violet"}
                      value={"Violet"}
                      style={{ backgroundColor: "Violet" }}
                    >
                      {" "}
                    </option>
                    <option
                      key={"Beige"}
                      value={"Beige"}
                      style={{ backgroundColor: "Beige" }}
                    >
                      {" "}
                    </option>
                    <option
                      key={"Orange"}
                      value={"Orange"}
                      style={{ backgroundColor: "Orange" }}
                    >
                      {" "}
                    </option>
                  </select>
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Nombre de Km / jour
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "100%" }}
                    value={state.NbrKm}
                    onChange={handleChange("NbrKm")}
                    inputProps={{
                      name: "NbrKm",
                      id: "NbrKm-native-simple",
                    }}
                  >
                    <option key={""} value={""}>
                      {""}
                    </option>
                    <option key={"lessthanFive"} value={"lessthanFive"}>
                      {" "}
                      Moins de 5 km
                    </option>
                    <option
                      key={"betweenFiveAndTen"}
                      value={"betweenFiveAndTen"}
                    >
                      Entre 5 et 10 km
                    </option>
                    <option
                      key={"betweenTenAndTwenty"}
                      value={"betweenTenAndTwenty"}
                    >
                      Entre 10 et 20 km
                    </option>
                    <option
                      key={"betweenTwentyAndThirty"}
                      value={"betweenTwentyAndThirty"}
                    >
                      Entre 20 et 30 km
                    </option>
                    <option
                      key={"betweenThirtyAndFourty"}
                      value={"betweenThirtyAndFourty"}
                    >
                      Entre 30 et 40 km
                    </option>
                    <option
                      key={"betweenFourtyAndFifty"}
                      value={"betweenFourtyAndFifty"}
                    >
                      Entre 40 et 50 km
                    </option>
                    <option key={"MoreThanFifty"} value={"MoreThanFifty"}>
                      Plus que 50 km
                    </option>
                  </select>
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Lieu de stationnement
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "100%" }}
                    value={state.stationPlace}
                    onChange={handleChange("stationPlace")}
                    inputProps={{
                      name: "stationPlace",
                      id: "stationPlace-native-simple",
                    }}
                  >
                    <option key={""} value={""}>
                      {""}
                    </option>
                    <option key={"Garage"} value={"Garage"}>
                      Garage
                    </option>
                    <option key={"Parking"} value={"Parking"}>
                      Parking
                    </option>
                    <option
                      key={"ParkingUnderground"}
                      value={"ParkingUnderground"}
                    >
                      Parking sous sol
                    </option>
                    <option key={"Rue"} value={"Rue"}>
                      Rue
                    </option>
                  </select>
                </div>

                <div
                  className="col-xl-6 col-md-12 col-sm-12 mt-3"
                  style={{ display: "inline-block" }}
                >
                  <label style={{ width: "100%", textAlign: "center" }}>
                    Compagnie d'assurance
                  </label>
                  <select
                    className="WinwinInput"
                    native
                    style={{ width: "100%" }}
                    value={state.insuranceCompany}
                    onChange={handleChange("insuranceCompany")}
                    inputProps={{
                      name: "insuranceCompany",
                      id: "insuranceCompany-native-simple",
                    }}
                  >
                    <option key={""} value={""}>
                      {""}
                    </option>
                    <option key={"GAT"} value={"GAT"}>
                      GAT
                    </option>
                    <option key={"UPCAR"} value={"UPCAR"}>
                      UPCAR
                    </option>
                    <option key={"ASTREE"} value={"ASTREE"}>
                      ASTREE
                    </option>
                    <option key={"AMI"} value={"AMI"}>
                      AMI
                    </option>
                    <option key={"Lloyd"} value={"Lloyd"}>
                      Lloyd
                    </option>
                    <option key={"COMAR"} value={"COMAR"}>
                      COMAR
                    </option>
                  </select>
                </div>
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        );
      case 2:
        return (
          <form className="col-xl-9 col-md-12 col-sm-12 m-auto">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid>
                <div className="col-xl-6 col-md-12 col-sm-12">
                  <label>Photo de votre voiture</label>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="raised-button-file"
                    multiple
                    type="file"
                  />
                </div>

                {/*   <div className="col-xl-6 col-md-12 col-sm-12">
                  <label>Date de naissance</label>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="raised-button-file"
                    multiple
                    type="file"
                  />
                </div> */}
              </Grid>
            </MuiPickersUtilsProvider>
          </form>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep === steps.length - 1) {
      SliderAddAction(state);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /*   const handleReset = () => {
    setActiveStep(0);
  }; */

  /* const onChange = e => {
    setState({ [e.target.name]: e.target.value });
  }; */
  return (
    <div className={classes.root} style={{ marginTop: "2em" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <span
                  style={{
                    color: "#EB2735",
                    fontFamily: "Montserrat",
                    fontWeight: "Bold",
                    fontSize: "2.2em",
                  }}
                >
                  Merci !
                </span>
                <span
                  style={{
                    color: "#012C3D",
                    fontFamily: "Poppins",
                    fontWeight: "Bold",
                    marginTop: "0.6em",
                    fontSize: "1.2em",
                  }}
                >
                  Un email vous seras envoyez si vous êtes choisis pour une
                  compagne
                </span>
                {/*   <span
                  style={{
                    color: "#012C3D",
                    fontFamily: "Poppins",
                    fontWeight: "Bold",
                    fontSize: "1.2em",
                    marginTop: "0.6em",
                  }}
                >
                  Nous vous appellerons pour terminer votre inscription.
                </span> */}
                <img
                  src="/Thanks.jpg"
                  alt="thankYou"
                  style={{ width: "75%" }}
                />
              </div>
            </Typography>
          </div>
        ) : (
          <div>
            <div className={classes.instructions}>
              {getStepContent(activeStep)}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="mt-3"
            >
              <button
                disabled={activeStep === 0}
                onClick={handleBack}
                className="WinWinNavButtonRED"
                style={{ width: "15%" }}
              >
                Back
              </button>
              <button
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={handleNext}
                className="WinWinNavButtonRED"
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(StepperComponent);

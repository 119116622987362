import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../Redux/actions/auth";
import "./login.css";
export class Login extends Component {
  state = {
    PersonalEmail: "",
    Password: "",
    userType: "ambassador",
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.login(
      this.state.PersonalEmail,
      this.state.Password,
      this.state.userType
    );
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/Dashboard" />;
    }
    const { PersonalEmail, Password } = this.state;
    return (
      <div
        style={{
          marginTop: "4em",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        className=" container col-md-12 col-xl-8 col-lg-8 col-sm-8 flexColumn"
      >
        <div style={{}}>
          <h2
            className="text-center"
            style={{ fontSize: "2.2em", fontFamily: "Montserrat" }}
          >
            Se connecter
          </h2>
          <form
            onSubmit={this.onSubmit}
            className="form mt-4"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "cennter",
              alignItems: "center",
            }}
          >
            <div
              className="form-group"
              style={{ width: "90%", margin: "auto" }}
            >
              <input
                type="text"
                className="form-control WinwinInput InputOpac"
                name="PersonalEmail"
                onChange={this.onChange}
                value={PersonalEmail}
                placeholder="Email"
                style={{
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                }}
              />
            </div>

            <div
              className="form-group mt-3"
              style={{ width: "90%", margin: "auto" }}
            >
              <input
                type="Password"
                className="form-control WinwinInput InputOpac"
                name="Password"
                onChange={this.onChange}
                value={Password}
                placeholder="Mot de passe"
                style={{
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                }}
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary WinWinNavButtonRED mt-3"
              style={{
                width: "90%",
                backgroundColor: "#EB2735",
                borderColor: "#EB2735",
                fontFamily: "Montserrat",
                fontWeight: "bold",
                fontSize: "1.2em",
                borderTopRightRadius: "25px",
                borderBottomRightRadius: "25px",
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
              }}
            >
              Se connecter
            </button>
            <p
              className="mt-3"
              style={{
                textAlign: "center",
                color: "#878787",
                fontFamily: "poppins",
                fontWeight: "light",
                fontSize: "0.8em",
              }}
            >
              Vous avez pas un compte ?{" "}
              <Link
                to="/register"
                style={{
                  color: "#D8464F",
                  fontFamily: "poppins",
                  fontWeight: "600",
                  fontSize: "1.1em",
                }}
              >
                Rejoinez nous.
              </Link>
            </p>
            <span
              style={{
                color: "#D8464F",
                fontFamily: "poppins",
                fontWeight: "600",
                fontSize: "0.9em",
              }}
            >
              Mot de passe oublié ?
            </span>
          </form>
        </div>

        <div id="imageLogin" style={{ marginTop: "3em" }}>
          <img alt="Se Connecter" src="/login.jpg" style={{ width: "30vw" }} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { login })(Login);

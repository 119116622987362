import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register } from "../../Redux/actions/auth";
import { createMessage } from "../../Redux/actions/messages";
import { faBullhorn, faCar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Register.css";

export class Register extends Component {
  constructor(props) {
    super(props);
    this.HandleClick = this.HandleClick.bind(this);
    this.state = {
      FirstName: "",
      LastName: "",
      PersonalNumber: "",
      PersonalEmail: "",
      Password: "",
      Password2: "",
      redirect: "",
      userType: "ambassador",
      styleAmbassador: {
        color: "#EB2735",
        borderColor: "#EB2735",
        backgroundColor: "#ffffff",
        cursor: "pointer",
        border: "1px solid",
        borderTopLeftRadius: "25px",
        borderBottomLeftRadius: "25px",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "15px",
        fontWeight: "700",
        textAlign: "center",

        height: "51px",
        zIndex: "1",
        position: "relative",
        width: "100%",
      },
      styleAnnonceur: {
        color: "#EB2735",
        borderColor: "#EB2735",
        backgroundColor: "#ffffff",
        cursor: "pointer",
        border: "1px solid",
        borderTopRightRadius: "25px",
        borderBottomRightRadius: "25px",
        fontFamily: "'Montserrat' sans-serif",
        fontSize: "15px",
        fontWeight: "700",
        textAlign: "center",

        height: "51px",
        position: "relative",
        width: "100%",
        zIndex: "1",
      },
    };
  }
  componentWillMount() {
    if (this.props.location.state) {
      const { SelectableUser } = this.props.location.state;
      this.HandleClick(SelectableUser);
    } else {
      this.HandleClick("ClickAmbassadeur");
    }
  }
  static propTypes = {
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      FirstName,
      LastName,
      PersonalNumber,
      PersonalEmail,
      Password,
      Password2,
      userType,
    } = this.state;
    if (Password !== Password2) {
      this.props.createMessage({ PasswordNotMatch: "Passwords do not match" });
    } else {
      const newUser = {
        FirstName,
        LastName,
        PersonalNumber,
        Password,
        PersonalEmail,
      };
      this.props.register(newUser, userType);
      this.setState({ redirect: "Accueil" });
    }
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  HandleClick = (e) => {
    if (e === "ClickAmbassadeur") {
      this.setState({
        userType: "ambassador",
        styleAmbassador: {
          color: "#ffffff",
          borderColor: "#EB2735",
          backgroundColor: "#EB2735",
          cursor: "pointer",
          border: "1px solid",
          borderTopLeftRadius: "25px",
          borderBottomLeftRadius: "25px",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "19px",
          fontWeight: "700",
          textAlign: "center",

          height: "51px",
          outline: "0",
          zIndex: "1",
          width: "100%",
        },
        styleAnnonceur: {
          color: "#EB2735",
          borderColor: "#EB2735",
          backgroundColor: "#ffffff",
          cursor: "pointer",
          border: "1px solid",
          borderTopRightRadius: "25px",
          borderBottomRightRadius: "25px",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "15px",
          fontWeight: "700",
          textAlign: "center",

          height: "48px",

          zIndex: "1",
          position: "relative",
          marginTop: "1px",
          width: "100%",
        },
      });
    } else if (e === "ClickAnnonceur") {
      this.setState({
        userType: "advertiser",
        styleAnnonceur: {
          color: "#ffffff",
          borderColor: "#EB2735",
          backgroundColor: "#EB2735",
          cursor: "pointer",
          border: "1px solid",
          borderTopRightRadius: "25px",
          borderBottomRightRadius: "25px",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "19px",
          fontWeight: "700",
          textAlign: "center",

          height: "51px",
          outline: "0",
          position: "relative",
          zIndex: "1",
          width: "100%",
        },
        styleAmbassador: {
          color: "#EB2735",
          borderColor: "#EB2735",
          backgroundColor: "#ffffff",
          cursor: "pointer",
          border: "1px solid",
          borderTopLeftRadius: "25px",
          borderBottomLeftRadius: "25px",
          fontFamily: "'Montserrat', sans-serif",
          fontSize: "15px",
          fontWeight: "700",
          textAlign: "center",

          height: "48px",
          position: "relative",
          marginTop: "1px",
          zIndex: "1",
          width: "100%",
        },
      });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    if (this.props.isAuthenticated) {
      return <Redirect to="/Accueil" />;
    }
    const {
      FirstName,
      LastName,
      PersonalNumber,
      PersonalEmail,
      Password,
      Password2,
    } = this.state;

    return (
      <div
        className=" container col-md-12 col-xl-5 col-lg-5 col-sm-5  "
        style={{ marginTop: "1em" }}
      >
        <div style={{ paddingTop: "10%" }}>
          <h2
            className="text-center "
            style={{
              marginBottom: "5%",
              marginTop: "62% !important",
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: "600",
              fontSize: "2.1em",
            }}
          >
            Créer un compte{" "}
            <span style={{ color: "#EB2735" }}>gratuitement</span>
          </h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%" }}>
              <button
                className="nav-link"
                onClick={() => this.HandleClick("ClickAmbassadeur")}
                style={this.state.styleAmbassador}
              >
                <FontAwesomeIcon icon={faCar} style={{}} className=" mr-3" />
                Ambassadeur
              </button>
            </div>
            <div
              style={{
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "18px",
                fontWeight: "700",
                cursor: "pointer",
                color: "#EB2735",
                borderColor: "#EB2735",
                backgroundColor: "#EB2735",
                border: "1px solid",
                borderTopRightRadius: "25px",
                borderBottomRightRadius: "25px",
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                position: "absolute",
                zIndex: "2",
              }}
            ></div>
            <div style={{ width: "100%" }}>
              <button
                className="nav-link"
                onClick={() => this.HandleClick("ClickAnnonceur")}
                style={this.state.styleAnnonceur}
              >
                Annonceur
                <FontAwesomeIcon icon={faBullhorn} className=" ml-3" />
              </button>
            </div>
          </div>

          <form
            onSubmit={this.onSubmit}
            className="mt-4"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="form-group d-inline-block pRight2"
                style={{ width: "53%", paddingRight: "3em" }}
              >
                <input
                  type="text"
                  placeholder="Prénom"
                  className="form-control WinwinInput InputOpac"
                  name="FirstName"
                  onChange={this.onChange}
                  value={FirstName}
                  required
                />
              </div>
              <div
                className="form-group d-inline-block "
                style={{ width: "45.5%" }}
              >
                <input
                  type="text"
                  className="form-control WinwinInput InputOpac"
                  placeholder="Nom"
                  name="LastName"
                  onChange={this.onChange}
                  value={LastName}
                  required
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="form-group d-inline-block  pRight2"
                style={{ width: "53%", paddingRight: "3em" }}
              >
                <input
                  type="text"
                  className="form-control WinwinInput InputOpac"
                  placeholder="Numéro de téléphone"
                  name="PersonalNumber"
                  onChange={this.onChange}
                  value={PersonalNumber}
                  required
                />
              </div>
              <div
                className="form-group d-inline-block  "
                style={{ width: "45.5%" }}
              >
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control WinwinInput InputOpac"
                  name="PersonalEmail"
                  onChange={this.onChange}
                  value={PersonalEmail}
                  required
                />
              </div>
            </div>{" "}
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <div
                className="form-group d-inline-block pRight2 "
                style={{ width: "53%", paddingRight: "3em" }}
              >
                <input
                  placeholder="mot de passe"
                  type="Password"
                  className="form-control WinwinInput InputOpac"
                  name="Password"
                  onChange={this.onChange}
                  value={Password}
                  required
                />
              </div>
              <div
                className="form-group d-inline-block  "
                style={{ width: "45.5%" }}
              >
                <input
                  type="Password"
                  placeholder="Confirmez votre mot de passe"
                  className="form-control WinwinInput InputOpac"
                  name="Password2"
                  onChange={this.onChange}
                  value={Password2}
                  required
                />
              </div>
            </div>{" "}
            <div className="form-group d-inline-block  ">
              <button
                type="submit"
                className="btn btn-primary "
                style={{
                  width: "100%",
                  backgroundColor: "#EB2735",
                  borderColor: "#EB2735",
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: "800",
                  borderTopRightRadius: "25px",
                  borderBottomRightRadius: "25px",
                  borderTopLeftRadius: "25px",
                  borderBottomLeftRadius: "25px",
                  paddingBottom: "0.4em",
                  paddingTop: "0.4em",
                  paddingLeft: "4em",
                  paddingRight: "4em",
                  fontSize: "1.2em",
                }}
              >
                Créer un compte
              </button>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  width: "194px",
                  height: "28px",

                  textAlign: "center",
                  fontFamily: "Poppins ",
                  fontWeight: "800",
                  letterSpacing: "0px",
                  color: "#EB2735",
                }}
              >
                Se Connectez avec
              </p>
              <img alt="Se Connecter" src="/facebook.png" />
              <img alt="Se Connecter" src="/search.png" className="ml-5" />
            </div>
            <p
              style={{
                fontFamily: "Poppins ",
                fontSize: "13px",
                color: "#878787",
              }}
            >
              Vous avez déjà une compte ?
              <Link
                className="ml-1"
                to="/login"
                style={{
                  color: "#EB2735",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Connectez vous.
              </Link>
            </p>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { register, createMessage })(Register);

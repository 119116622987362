import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { contact } from "../../Redux/actions/auth";

export class Contact extends Component {
  state = {
    contact: { Name: "", Number: "", PersonalEmail: "", Message: "" },
  };
  static propTypes = {
    contact: PropTypes.func.isRequired,
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onSubmit = (e) => {
    e.preventDefault();
    this.props.contact(
      this.state.PersonalEmail,
      this.state.Password,
      this.state.userType
    );
  };
  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className=" col-lg-12 col-sm-12  ml-auto mr-auto"
      >
        <div
          id="ContactForm"
          style={{
            marginTop: "10%",
          }}
        >
          <div id="ContactTitle">
            {" "}
            <h2 style={{ fontSize: "35px", fontFamily: "Montserrat" }}>
              Let's keep in touch !
            </h2>
          </div>
          <div id="ContactFormItems" className="">
            <form className="form ">
              <input
                type="text"
                className="form-control mt-2 WinwinInput InputOpac"
                name="Name"
                onChange={this.onChange}
                value={this.state.Name}
                placeholder="Prénom & Nom"
              />
              <input
                type="text"
                className="form-control mt-3 WinwinInput InputOpac"
                name="Number"
                onChange={this.onChange}
                value={this.state.Number}
                placeholder="Numéro de téléphone"
              />{" "}
              <input
                type="text"
                className="form-control mt-3 WinwinInput InputOpac"
                name="PersonalEmail"
                onChange={this.onChange}
                value={this.state.PersonalEmail}
                placeholder="Email"
              />{" "}
              <textarea
                type="text"
                className="form-control mt-3 TextareaOpac WinwinInput "
                name="Message"
                onChange={this.onChange}
                value={this.state.Message}
                placeholder="Message"
                style={{
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  fontFamily: "Poppins",
                  fontWeight: 700,
                  minHeight: "11em",
                }}
              ></textarea>
              <button
                type="submit"
                className="btn btn-primary mt-4"
                style={{
                  width: "100%",
                  backgroundColor: "#EB2735",
                  borderColor: "#EB2735",
                  fontWeight: 800,
                  fontSize: "1.2em",
                  fontFamily: "Montserrat",
                  lineHeight: "20px",
                  borderTopRightRadius: "25px",
                  borderBottomRightRadius: "25px",
                  borderTopLeftRadius: "25px",
                  borderBottomLeftRadius: "25px",
                  paddingTop: "0.6em",
                  paddingBottom: "0.6em",
                }}
              >
                Envoyer
              </button>
            </form>
          </div>
        </div>
        <div className="" style={{}}>
          <img
            alt="contact"
            className="img-fluid"
            src="/contact.jpg"
            style={{
              height: "30em",
              width: "29em",
              marginTop: "5em",
            }}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  contact: state.contact,
});
export default connect(mapStateToProps, { contact })(Contact);

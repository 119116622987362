import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../Redux/actions/auth";
import DisconnectModal from "./LogoutConfirmation";
export class Header extends Component {
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
  }
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  };
  state = {
    SideBarOn: false,
    mleft: "-110%",
  };
  render() {
    const { isAuthenticated, user } = this.props.auth;

    const authLinks = (
      <ul className="navbar-nav">
        <span className="navbar-text mr-3">
          <strong>{user ? ` ${user.FirstName}  ${user.LastName}` : ""}</strong>
        </span>
        <li className="nav-item" key="Disconnect">
          <DisconnectModal />
        </li>
      </ul>
    );

    const guestLinks = [];
    guestLinks.push(
      <li className="nav-item" key="Login">
        <Link
          to="/login"
          className="nav-item"
          style={{
            fontFamily: "'Poppins', sans-serif",
            color: "#012C3D",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Connexion
        </Link>
      </li>
    );

    guestLinks.push(
      <div
        key="test"
        style={{
          fontfamily: "'Poppins', sans-serif",
          fontSize: "15px",
          fontWeight: "600",
        }}
      >
        <Link
          to="/register"
          className=""
          style={{
            display: "block",
            fontSize: "14px",
            color: "#eb2735",
            width: "100%",
          }}
        >
          <li
            className="nav-item WinWinNavButtonWhite"
            key="RegisterMobile"
            style={{
              margin: "2em",
              marginLeft: "0.5em",
              boxShadow: "0px 2px 0px #e2e2e2",
              width: "100%",
            }}
          >
            Inscription
          </li>{" "}
        </Link>
      </div>
    );

    const authLinksMobile = (
      <ul
        className="navbar-nav "
        style={{
          padding: "2em",
          paddingLeft: "0.5em",
          boxShadow: "0px 2px 0px #e2e2e2",
        }}
      >
        <span className="navbar-text mr-3">
          <strong>{user ? ` ${user.FirstName}  ${user.LastName}` : ""}</strong>
        </span>
        <li
          className="nav-item"
          key="DisconnectMobile"
          onClick={() => {
            if (this.state.SideBarOn === true) {
              this.setState({ SideBarOn: false, mleft: "-110%" });
            } else {
              this.setState({ SideBarOn: true, mleft: "0%" });
            }
          }}
        >
          <DisconnectModal />
        </li>
      </ul>
    );

    const guestLinksMobile = [];
    guestLinksMobile.push(
      <Link
        to="/login"
        className="nav-item"
        style={{
          fontFamily: "'Poppins', sans-serif",
          color: "#012C3D",
          fontSize: "15px",
          fontWeight: "600",
        }}
        onClick={() => {
          if (this.state.SideBarOn === true) {
            this.setState({ SideBarOn: false, mleft: "-110%" });
          } else {
            this.setState({ SideBarOn: true, mleft: "0%" });
          }
        }}
      >
        {" "}
        <li
          className="nav-item"
          key="LoginMobile"
          style={{
            padding: "2em",
            paddingLeft: "0.5em",
            boxShadow: "0px 2px 0px #e2e2e2",
          }}
        >
          Connexion
        </li>
      </Link>
    );

    guestLinksMobile.push(
      <div
        key="test"
        style={{
          fontfamily: "'Poppins', sans-serif",
          fontSize: "15px",
          fontWeight: "600",
        }}
      >
        <Link
          to="/register"
          className=""
          style={{
            display: "block",
            fontSize: "14px",
            color: "#eb2735",
            width: "100%",
          }}
          onClick={() => {
            if (this.state.SideBarOn === true) {
              this.setState({ SideBarOn: false, mleft: "-110%" });
            } else {
              this.setState({ SideBarOn: true, mleft: "0%" });
            }
          }}
        >
          <li
            className="nav-item WinWinNavButtonWhite"
            key="RegisterMobile"
            style={{
              margin: "2em",
              marginLeft: "0.5em",
              boxShadow: "0px 2px 0px #e2e2e2",
              width: "100%",
            }}
          >
            Inscription
          </li>{" "}
        </Link>
      </div>
    );
/*    const SideBarEaseIn = () => {
      const ButtonNode = this.buttonRef.current;
    };*/
    return (
      <div
        className="navbarWinWin "
        style={{
          width: "96%",
          height: "60px",
          zIndex: "20",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          marginLeft: "2%",
        }}
      >
        <nav
          className={
            "navbar navbar-expand-xl side-drawer navbar-light sticky-top col-xl-8"
          }
          style={{}}
        >
          {isAuthenticated ? (
            <Link
              to="/Dashboard"
              className="nav-brand navbar-toggler logoMobileLeft"
              style={{ border: 0 }}
              onClick={() => {
                this.setState({ SideBarOn: false, mleft: "-110%" });
              }}
            >
              <img
                alt="WinWin"
                src="/winwin.png"
                style={{
                  width: "140px",
                  height: "45px",
                }}
              />
            </Link>
          ) : (
            <Link
              to="/Accueil"
              className="nav-brand navbar-toggler "
              style={{ border: 0 }}
              onClick={() => {
                this.setState({ SideBarOn: false, mleft: "-110%" });
              }}
            >
              <img
                alt="WinWin"
                src="/winwin.png"
                style={{
                  width: "135px",
                  height: "40px",
                }}
              />
            </Link>
          )}
          <button
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={this.buttonRef}
            id="ToggleButton"
            className="navbar-toggler"
            style={{ marginTop: "-1.5%" }}
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            onClick={() => {
              if (this.state.SideBarOn === true) {
                this.setState({ SideBarOn: false, mleft: "-110%" });
              } else {
                this.setState({ SideBarOn: true, mleft: "0%" });
              }
            }}
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div
            className="collapse navbar-collapse"
            id="navbarResponsive"
            style={{
              alignSelf: "baseline",
              justifyContent: "center",
              width: "100%",
              margin: "auto",
            }}
          >
            <ul
              className="navbar-nav mt-2 mt-lg-0"
              style={{
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
              }}
            >
              <li className="nav-item active " key="Dashboard">
                <Link
                  to="/Accueil"
                  className="nav-item"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    color: "#012C3D",
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "25px",
                  }}
                >
                  Accueil
                </Link>
              </li>
              <li className="nav-item" key="contact">
                <Link
                  to="/contact"
                  className="nav-item"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    color: "#012C3D",
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "25px",
                  }}
                >
                  Contact
                </Link>
              </li>
              <li className="nav-item" key="Ambassador">
                <Link
                  to="/Ambassador"
                  className="nav-item"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    color: "#012C3D",
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "25px",
                  }}
                >
                  Ambassadeur
                </Link>
              </li>
              <li>
                {isAuthenticated ? (
                  <Link to="/Dashboard" className="nav-brand ">
                    <img
                      alt="WinWin"
                      src="/winwin.png"
                      style={{
                        width: "135px",
                        height: "40px",
                        marginTop: "0.4em",
                      }}
                    />
                  </Link>
                ) : (
                  <Link to="/Accueil" className="nav-brand ">
                    <img
                      alt="WinWin"
                      src="/winwin.png"
                      style={{
                        width: "135px",
                        height: "40px",
                        marginTop: "0.4em",
                      }}
                    />
                  </Link>
                )}
              </li>
              <li className="nav-item" key="Advertiser">
                <Link
                  to="/Advertiser"
                  className="nav-item"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    color: "#012C3D",
                    fontSize: "15px",
                    fontWeight: "600",
                    lineHeight: "25px",
                  }}
                >
                  Annonceur
                </Link>
              </li>
              {isAuthenticated ? authLinks : guestLinks}
            </ul>
          </div>

          <div
            className=""
            id="navbarResponsive"
            style={{
              alignSelf: "baseline",
              width: "80%",
              height: "100vh",
              marginTop: "56px",
              position: "absolute",
              marginLeft: this.state.mleft,
              display: "inline-block",
              backgroundColor: "white",
            }}
          >
            <ul
              className="navbar-nav mt-2 mt-lg-0 col-md-12"
              style={{
                width: "100%",
              }}
            >
              <Link
                to="/Accueil"
                className="nav-item"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  color: "#012C3D",
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "25px",
                }}
                onClick={() => {
                  if (this.state.SideBarOn === true) {
                    this.setState({ SideBarOn: false, mleft: "-110%" });
                  } else {
                    this.setState({ SideBarOn: true, mleft: "0%" });
                  }
                }}
              >
                <li
                  className="nav-item active "
                  key="DashboardMobile"
                  style={{
                    padding: "2em",
                    paddingLeft: "0.5em",

                    boxShadow: "0px 2px 0px #e2e2e2",
                  }}
                >
                  Accueil
                </li>{" "}
              </Link>
              <Link
                to="/contact"
                className="nav-item"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  color: "#012C3D",
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "25px",
                }}
                onClick={() => {
                  if (this.state.SideBarOn === true) {
                    this.setState({ SideBarOn: false, mleft: "-110%" });
                  } else {
                    this.setState({ SideBarOn: true, mleft: "0%" });
                  }
                }}
              >
                <li
                  className="nav-item"
                  key="contactMobile"
                  style={{
                    padding: "2em",
                    paddingLeft: "0.5em",
                    boxShadow: "0px 2px 0px #e2e2e2",
                  }}
                >
                  Contact
                </li>
              </Link>
              <Link
                to="/Ambassador"
                className="nav-item"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  color: "#012C3D",
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "25px",
                }}
                onClick={() => {
                  if (this.state.SideBarOn === true) {
                    this.setState({ SideBarOn: false, mleft: "-110%" });
                  } else {
                    this.setState({ SideBarOn: true, mleft: "0%" });
                  }
                }}
              >
                <li
                  className="nav-item"
                  key="AmbassadorMobile"
                  style={{
                    padding: "2em",
                    paddingLeft: "0.5em",
                    boxShadow: "0px 2px 0px #e2e2e2",
                  }}
                >
                  Ambassadeur
                </li>
              </Link>
              <li style={{ display: "none" }}>
                {isAuthenticated ? (
                  <Link
                    to="/Dashboard"
                    className="nav-brand "
                    onClick={() => {
                      if (this.state.SideBarOn === true) {
                        this.setState({ SideBarOn: false, mleft: "-110%" });
                      } else {
                        this.setState({ SideBarOn: true, mleft: "0%" });
                      }
                    }}
                  >
                    <img
                      alt="WinWin"
                      src="/winwin.png"
                      style={{
                        width: "135px",
                        height: "40px",
                        marginTop: "0.4em",
                      }}
                    />
                  </Link>
                ) : (
                  <Link
                    to="/Accueil"
                    className="nav-brand "
                    onClick={() => {
                      if (this.state.SideBarOn === true) {
                        this.setState({ SideBarOn: false, mleft: "-110%" });
                      } else {
                        this.setState({ SideBarOn: true, mleft: "0%" });
                      }
                    }}
                  >
                    <img
                      alt="WinWin"
                      src="/winwin.png"
                      style={{
                        width: "135px",
                        height: "40px",
                        marginTop: "0.4em",
                      }}
                    />
                  </Link>
                )}
              </li>
              <Link
                to="/Advertiser"
                className="nav-item"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  color: "#012C3D",
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "25px",
                }}
                onClick={() => {
                  if (this.state.SideBarOn === true) {
                    this.setState({ SideBarOn: false, mleft: "-110%" });
                  } else {
                    this.setState({ SideBarOn: true, mleft: "0%" });
                  }
                }}
              >
                <li
                  className="nav-item"
                  key="AdvertiserMobile"
                  style={{
                    padding: "2em",
                    paddingLeft: "0.5em",
                    boxShadow: "0px 2px 0px #e2e2e2",
                  }}
                >
                  Annonceur
                </li>
              </Link>
              {console.log(isAuthenticated)}
              {isAuthenticated == null ? guestLinksMobile : authLinksMobile}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Header);

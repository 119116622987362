import React from "react";

import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div
      style={{
        width: "96%",
        height: "60px",
        borderTop: "0.5px solid #70707088",
        opacity: "1",
        borderLeft: "white",
        borderRight: "white",
        justifyContent: "center",
        display: "flex",
        alignSelf: "center",
        margin: "auto",
      }}
    >
      <div
        className="col-xl-8"
        style={{
          justifyContent: "space-around",
          display: "flex",
          alignItems: "center",
          marginTop: "1em",
          paddingBottom: "0px",
          paddingTop: "0px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex" }}>
            <img
              src="/envelope.png"
              alt="enveloppe"
              style={{ margin: "auto" }}
            />
            <span
              className="ml-1 FontSmall"
              style={{
                color: "#000000",
                fontFamily: "'Poppins', sans-serif",
                fontSize: "1em",
                fontWeight: "600",
              }}
            >
              Contact_WinWin@gmail.com
            </span>
          </div>

          <div style={{ display: "flex" }}>
            <img src="/telephone.png" alt="enveloppe" />
            <span
              className="ml-1 FontSmall"
              style={{
                color: "#000000",
                fontFamily: "'Poppins', sans-serif",
                fontSize: "1em",
                fontWeight: "600",
              }}
            >
              +216 55228585
            </span>
          </div>
        </div>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <a href="http://www.facebook.com" style={{ paddingRight: "1em" }}>
              <img
                src="/facebookLogo.png"
                alt="fblogo"
                style={{
                  width: "26px",
                  height: "26px",
                }}
              />
            </a>
            <a href="http://www.instagram.com">
              <img
                src="/instagramLogo.png"
                alt="Instalogo"
                style={{ width: "26px", height: "26px" }}
              />
            </a>
          </div>
          <div
            className="DNonetxt"
            style={{
              fontSize: "9px",
              fontWeight: "900",
              fontFamily: "'Poppins', sans-serif",
              marginTop: "1em",
            }}
          >
            Made with © 2020 winwin.com.tn
          </div>
        </div>
        <div
          className="flexColumn "
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{ marginRight: "1em", display: "flex" }}
            className="DNonetxt "
          >
            <span
              style={{
                fontFamily: "'Poppins', sans-serif",
                color: "#000000",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "4px",
              }}
            >
              Des questions ?
            </span>
          </div>

          <Link
            to="/contact"
            className="nav-link"
            style={{
              color: "#EB2735",
              borderColor: "#EB2735",
              border: "1px solid",
              borderTopRightRadius: "25px",
              borderBottomRightRadius: "25px",
              borderTopLeftRadius: "25px",
              borderBottomLeftRadius: "25px",
              textAlign: "center",
              padding: "0px",
              paddingTop: "0.3em",
              paddingBottom: "0.3em",
              paddingLeft: "0.5em",
              paddingRight: "0.5em",
              display: "flex",
            }}
          >
            <span
              className="FontSmall"
              style={{
                fontFamily: "'Poppins', sans-serif",
                fontSize: "12px",
                fontWeight: "800",
                textAlign: "center",
                margin: "auto",
              }}
            >
              Contactez nous
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

import { combineReducers } from "redux";
import campaign from "./campaign";
import errors from "./errors";
import message from "./messages";
import auth from "./auth";
export default combineReducers({
  campaign,
  errors,
  message,
  auth
});

import React, { Component } from "react";
import { Link } from "react-router-dom";

class AmbassadorAccueil extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opaimg1: "1",
      opaimg2: "1",
      opaimg3: "1",
      opaimg4: "1",
      opatxt1: "0",
      opatxt2: "0",
      opatxt3: "0",
      opatxt4: "0",
      opatxthab1: "1",
      opatxthab2: "1",
      opatxthab3: "1",
      opaimghab1: "0",
      opaimghab2: "0",
      opaimghab3: "0",
    };
  }
  showtxt = (nb) => {
    if (nb === 1) {
      this.setState({
        ...this.state,
        opaimg1: "0",
        opatxt1: "1",
        opaimghab1: "0",
        opatxthab1: "1",
      });
    } else if (nb === 2) {
      this.setState({
        ...this.state,
        opaimg2: "0",
        opatxt2: "1",
        opaimghab2: "0",
        opatxthab2: "1",
      });
    } else if (nb === 3) {
      this.setState({
        ...this.state,
        opaimg3: "0",
        opatxt3: "1",
        opaimghab3: "0",
        opatxthab3: "1",
      });
    } else if (nb === 4) {
      this.setState({
        ...this.state,
        opaimg4: "0",
        opatxt4: "1",
        opaimghab4: "0",
        opatxthab4: "1",
      });
    }
  };

  hidetxt = (nb) => {
    if (nb === 1) {
      this.setState({
        ...this.state,
        opaimg1: "1",
        opatxt1: "0",
        opaimghab1: "1",
        opatxthab1: "0",
      });
    } else if (nb === 2) {
      this.setState({
        ...this.state,
        opaimg2: "1",
        opatxt2: "0",
        opaimghab2: "1",
        opatxthab2: "0",
      });
    } else if (nb === 3) {
      this.setState({
        ...this.state,
        opaimg3: "1",
        opatxt3: "0",
        opaimghab3: "1",
        opatxthab3: "0",
      });
    } else if (nb === 4) {
      this.setState({
        ...this.state,
        opaimg4: "1",
        opatxt4: "0",
        opaimghab4: "1",
        opatxthab4: "0",
      });
    }
  };

  render() {
    return (
      <div
        style={{ marginTop: "4em" }}
        className=" col-lg-12 col-sm-12  ml-auto mr-auto"
      >
        <div
          id="PictureTitle"
          className="col-md-8 col-lg-8 col-sm-8 ml-auto mr-auto"
          style={{ display: "flex" }}
        >
          <div className="">
            <img
              alt="winwinCar"
              className="img-fluid"
              src="/ambassadorCar.jpg"
            />
          </div>
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              className="resText Fsize16"
              style={{
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: "Bold",
                letterSpacing: "0px",
                color: "#012C3D",
                fontSize: "2.2rem",
                width: "11em",
                lineHeight: "1.2em",
              }}
            >
              Gagnez de l'argent en représentant vos marques préférées
            </span>
            <Link
              to={{
                pathname: "/register",
                state: {
                  SelectableUser: "ClickAmbassadeur",
                },
              }}
              style={{
                width: "60%",
                backgroundColor: "#EB2735",
                borderColor: "#EB2735",
                color: "#ffffff",
                fontFamily: " Montserrat",
                fontWeight: "600",
                borderTopRightRadius: "25px",
                borderBottomRightRadius: "25px",
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
                paddingLeft: "1%",
                paddingRight: "1%",
                paddingTop: "2%",
                paddingBottom: "2%",
                marginTop: "6%",
                textAlign: "center",
                fontSize: "1.2em",
              }}
            >
              Nous Rejoindre
            </Link>
          </div>
        </div>
        <div
          id="HowItWorks"
          className="mt-5 col-md-9 col-lg-9 col-sm-9 ml-auto mr-auto"
          style={{
            backgroundColor: "#78BDC4",
            borderRadius: "31px",
            zIndex: "2",
            position: "relative",
          }}
        >
          <div>
            <h1
              className="w-100 Fsize14"
              style={{
                textAlign: "center",
                paddingTop: "5%",
                color: "#ffffff",
                fontFamily: " Montserrat",
                fontWeight: "600",
              }}
            >
              Comment ça marche ?
            </h1>
          </div>
          <div
            style={{
              flex: "auto",
              display: "flex",
              justifyContent: "space-around",
              paddingBottom: "7%",
            }}
            className="Dblock"
          >
            <div
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1em",
                }}
              >
                <span style={{ fontSize: "1.7em", font: "Poppins" }}>1.</span>{" "}
                Inscription
              </div>

              <img
                alt="computer"
                src="/computer.png"
                style={{
                  marginTop: "10%",
                  opacity: this.state.opaimg1,
                  transition: "all 100ms ease-out",
                  width: "40%",
                  height: "6em",
                }}
                className="mt2em"
              />
              <div
                className="content w80Mt5em"
                onMouseEnter={() => this.showtxt(1)}
                onMouseLeave={() => this.hidetxt(1)}
                style={{
                  position: "absolute",
                  marginTop: "6%",
                  opacity: this.state.opatxt1,
                  transition: "all 100ms ease-in",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.85em",
                  width: "18.4%",
                  cursor: "default",
                  height: "30%",
                }}
              >
                <div className="text">
                  Inscrivez vous sur la plateforme. Nous vous poserons des
                  questions concernant votre voiture et vos trajets habituels.
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1em",
                }}
              >
                <span style={{ fontSize: "1.7em", font: "Poppins" }}>2.</span>{" "}
                Choix de la campagne
              </div>

              <img
                alt="choices"
                src="/choices.png"
                style={{
                  marginTop: "10%",
                  opacity: this.state.opaimg2,
                  transition: "all 100ms ease-out",
                  width: "40%",
                  height: "6em",
                }}
                className="mt2em"
              />
              <div
                onMouseEnter={() => this.showtxt(2)}
                onMouseLeave={() => this.hidetxt(2)}
                className="content w80Mt5em"
                style={{
                  position: "absolute",

                  marginTop: "6%",
                  opacity: this.state.opatxt2,
                  transition: "all 100ms ease-in",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.85em",
                  width: "18.4%",
                  cursor: "default",
                  height: "30%",
                }}
              >
                <div className="text">
                  Vous pouvez définir les objectifs de votre campagne en
                  choisissant votre zone cible, votre budget, le nombre de
                  voitures...
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                }}
              >
                <span style={{ fontSize: "1.7em", font: "Poppins" }}>3.</span>{" "}
                Suivi de la campagne
              </div>

              <img
                alt="car"
                src="/car.png"
                style={{
                  marginTop: "10%",
                  opacity: this.state.opaimg3,
                  transition: "all 100ms ease-out",
                  width: "40%",
                  height: "6em",
                }}
                className="mt2em"
              />
              <div
                onMouseEnter={() => this.showtxt(3)}
                onMouseLeave={() => this.hidetxt(3)}
                className="content w80Mt5em"
                style={{
                  position: "absolute",

                  marginTop: "6%",
                  opacity: this.state.opatxt3,
                  transition: "all 100ms ease-in",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.85em",
                  width: "18.4%",
                  cursor: "default",
                  height: "30%",
                }}
              >
                <div className="text">
                  Nos équipes s'occupent de l'habillage de votre voiture. Il ne
                  vous reste qu'à conduire et suivre votre rémunération sur la
                  plateforme.
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                }}
              >
                <span style={{ fontSize: "1.7em", font: "Poppins" }}> 4.</span>{" "}
                Rénumeration
              </div>

              <img
                alt="pay"
                src="/pay.png"
                style={{
                  marginTop: "10%",
                  opacity: this.state.opaimg4,
                  transition: "all 100ms ease-out",
                  width: "40%",
                  height: "6em",
                }}
                className="mt2em"
              />
              <div
                onMouseEnter={() => this.showtxt(4)}
                onMouseLeave={() => this.hidetxt(4)}
                className="content w80Mt5em"
                style={{
                  position: "absolute",

                  marginTop: "6%",
                  opacity: this.state.opatxt4,
                  transition: "all 100ms ease-in",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.85em",
                  width: "18.4%",
                  cursor: "default",
                  height: "30%",
                }}
              >
                <div className="text">
                  A la fin de la campagne, vous recevrez votre rémunération.
                  L'habillage sera retiré par des professionnels
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="Details"
          className="col-md-12 col-xl-12 col-sm-12 ml-auto mr-auto"
          style={{
            backgroundColor: "#e5e9ec",
            borderRadius: "31px",

            marginTop: "-5%",
            zIndex: "1",
            position: "relative",
            paddingBottom: "5em",
          }}
        >
          <div
            id="detailsTitle"
            style={{
              margin: "auto",
              top: "4em",
              position: " relative",
              width: "100%",
              height: "8em",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontWeight: "700",
              letterSpacing: "0px",
              fontSize: " 30px",
              color: " #012C3D",
            }}
          >
            Affichez, Roulez, Gagnez !
          </div>
          <div
            id="detailsContent"
            className="Dblock"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              id="detailContentLeft"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "25%",
              }}
              className="w100percent"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10em",
                  width: "10em",
                  backgroundColor: "#78BDC4",
                  borderRadius: "50%",
                }}
              >
                <img alt="piggy-bank" src="/piggy-bank.png" />
              </div>
              <div
                style={{
                  color: "#012C3D",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                  fontSize: "20px",
                  marginTop: "1em",
                  width: "100%",
                }}
              >
                Gagner de l'argent
              </div>
              <div
                style={{
                  position: " relative",
                  width: "50%",
                  height: "8em",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  color: " #000000",
                }}
              >
                Economizer les frais de votre voiture sans changer vos habitudes
              </div>
              <div
                style={{
                  height: "10em",
                  width: "10em",
                  backgroundColor: "#78BDC4",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img alt="wax" src="/wax.png" />
              </div>
              <div
                style={{
                  color: "#012C3D",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                  fontSize: "20px",

                  marginTop: "1em",
                  width: "100%",
                }}
              >
                Nouveau look
              </div>
              <div
                style={{
                  position: " relative",
                  width: "50%",
                  height: "8em",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  color: " #000000",
                }}
              >
                Changez le look de votre voiture chaque mois, c'est super fun !
              </div>
            </div>
            <div
              id="detailContentCenter"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <img
                  alt="winiwnguy"
                  src="/winiwnguy.jpg"
                  style={{ width: "100%", height: "100%" }}
                  className="DNonetxt"
                />
              </div>
              <div
                style={{
                  color: " #EB2735",
                  position: " relative",
                  borderRadius: "10px",
                  border: "3px solid",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  letterSpacing: "0px",
                  fontSize: "25px",
                  marginTop: "3.2em",
                  width: "17.5em",
                  paddingBottom: "0.3em",
                  paddingTop: "0.3em",
                  paddingLeft: "1em",
                  paddingRight: "1em",
                }}
                className="Fsize1 mt0em"
              >
                Gagnez jusqu'à 500D / mois
              </div>
            </div>
            <div
              id="detailContentRight"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "25%",
              }}
              className="w100percent mt3em"
            >
              <div
                style={{
                  height: "10em",
                  width: "10em",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#78BDC4",
                }}
              >
                <img alt="honest" src="/honest.png" />
              </div>
              <div
                style={{
                  color: "#012C3D",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                  fontSize: "20px",
                  marginTop: "1em",
                }}
              >
                Engagement
              </div>
              <div
                style={{
                  position: " relative",
                  width: "50%",
                  height: "8em",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  color: " #000000",
                }}
              >
                Devenez ambassadeur de vos marques préférées.
              </div>
              <div
                style={{
                  height: "10em",
                  width: "10em",
                  backgroundColor: "#78BDC4",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img alt="placeholder" src="/placeholder.png" />
              </div>
              <div
                style={{
                  color: "#012C3D",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "650",
                  letterSpacing: "0px",
                  fontSize: "20px",

                  marginTop: "1em",
                }}
              >
                Challenge
              </div>
              <div
                style={{
                  position: " relative",
                  width: "50%",
                  height: "8em",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  letterSpacing: "0px",
                  fontSize: "12px",
                  color: " #000000",
                }}
              >
                Participezs à nos challenges et gagnez plus d'argent.
              </div>
            </div>
          </div>
        </div>
        <div
          id="TypeHabillage"
          style={{
            backgroundColor: "#012C3D",
            borderRadius: "31px",
            zIndex: "2",
            position: "relative",
            marginTop: "-3em",
          }}
          className="col-md-9 col-lg-9 col-sm-9 ml-auto mr-auto"
        >
          <div>
            <h1
              className="w-100 Fsize2"
              style={{
                textAlign: "center",
                paddingTop: "5%",
                color: "#ffffff",
              }}
            >
              Type d'habillage
            </h1>
          </div>
          <div
            style={{
              flex: "auto",
              display: "flex",
              justifyContent: "space-evenly",
              paddingBottom: "7%",
            }}
            className="Dblock"
          >
            <div
              onMouseEnter={() => this.hidetxt(1)}
              onMouseLeave={() => this.showtxt(1)}
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "700",
                  letterSpacing: "0px",
                  position: "absolute",
                  fontSize: "30px",
                  opacity: this.state.opatxthab1,
                  transition: "all 100ms ease-out",
                }}
              >
                Stickers
              </div>

              <img
                alt="stickers"
                src="/stickers.jpg"
                style={{
                  opacity: this.state.opaimghab1,
                  transition: "all 100ms ease-in",
                  height: "70%",
                }}
              />
              {/*  */}
            </div>
            <div
              onMouseEnter={() => this.hidetxt(2)}
              onMouseLeave={() => this.showtxt(2)}
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "700",
                  letterSpacing: "0px",
                  position: "absolute",
                  fontSize: "30px",
                  opacity: this.state.opatxthab2,
                  transition: "all 100ms ease-out",
                }}
              >
                Partiel
              </div>

              <img
                alt="stickers"
                src="/partiel.jpg"
                style={{
                  opacity: this.state.opaimghab2,
                  transition: "all 100ms ease-in",
                  height: "70%",
                }}
              />
              {/*  */}
            </div>
            <div
              onMouseEnter={() => this.hidetxt(3)}
              onMouseLeave={() => this.showtxt(3)}
              style={{
                backgroundColor: "#ffffff",
                width: "20%",
                height: "13em",
                borderRadius: "25px",
                marginTop: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="w100percent"
            >
              <div
                style={{
                  color: "#EB2735",
                  textAlign: "center",
                  font: "Poppins",
                  fontWeight: "700",
                  letterSpacing: "0px",
                  position: "absolute",
                  fontSize: "30px",

                  opacity: this.state.opatxthab3,
                  transition: "all 100ms ease-out",
                }}
              >
                Total
              </div>

              <img
                alt="total"
                src="/total.jpg"
                style={{
                  opacity: this.state.opaimghab3,
                  transition: "all 100ms ease-in",
                  height: "70%",
                }}
              />
              {/*  */}
            </div>
          </div>
        </div>
        <div
          id="Details"
          style={{
            backgroundColor: "#e5e9ec",
            borderRadius: "31px",

            marginTop: "-5%",
            zIndex: "1",
            position: "relative",
            height: "fit-content",
          }}
        >
          <div
            id="detailsTitle"
            style={{
              margin: "auto",
              top: "1.5em",
              position: " relative",
              width: "100%",
              height: "7em",
              textAlign: "center",
              fontFamily: "Montserrat",
              fontWeight: "700",
              letterSpacing: "0px",
              fontSize: " 30px",
              color: " #EB2735",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "#B7B7B7",
                opacity: "40%",
                fontFamily: "Montserrat",
                fontWeight: "800",
                position: "absolute",
                fontSize: "2.2em",
              }}
            >
              FAQ
            </span>
            <span> QUESTION / REPONSE</span>
          </div>
          <div
            id="detailsContent"
            className="col-md-8 col-lg-8 col-sm-8 ml-auto mr-auto "
            style={{
              display: "flex",
              margin: "auto",
              flexDirection: "column",
              paddingBottom: "5em",
            }}
          >
            <div
              className="w-100"
              style={{
                backgroundColor: "#FFFFFF",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                1. Winوين, c'est quoi ?{" "}
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Winوين est une plateforme qui vous permet de gagner de l'argent
                en affichant une publicité sur votre voiture.{" "}
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                2. Qui peut utiliser Winوين ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Toute personne qui possède une voiture en bon état et un permis
                de conduire.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                3. Quel type de voiture ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Peu importe la couleur, le modèle, toute voiture propre, en bon
                état et qui a 10 ans ou moins.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                4. Si la voiture est au nom d'une autre personne, est ce que je
                peux l'utiliser pour Win وين?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Oui il faut avoir l'accord du propriétaire.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                5. Est ce que je peux choisir la campagne à laquelle je veux
                participer ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Oui bien sûr, vous pouvez choisir parmi les campagnes qui ont
                été sélectionnés pour vous.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                6. Combien dure une campagne ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Une campagne dure entre 15 jours et 3 mois.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                7. Combien de temps pour faut-il pour faire l'habillage de la
                voiture ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Cela dépend du type de l'habillage. Quelques minutes pour les
                stickers et une demi journée pour un habillage complet. A vous
                de choisir le créneaux horaire qui vous convient.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                8. Est ce que l'habillage peut endommager ma voiture ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                La pose et la dépose de l'habillage sont fait par des
                professionnels pour conserver votre voiture dans un excellent
                état.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                9. Combien je peux gagner ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Vous pouvez gagner jusqu'à 500Dt par mois. La rémunération
                dépend de plusieurs paramètres (type d'habillage, nombre de Km,
                zone, heure…)
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                10. Quand est ce que je vais recevoir ma rémunération ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                A la fin de la campagne, après la dépose de l'habillage vous
                recevrez la rémunération indiquée sur la plateforme.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                11. Je ne trouve aucune campagne sur la plateforme ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Vous serez informé dès que vous êtes sélectionné pour une
                campagne, et vous avez le choix de l'accepter ou la refuser.
              </p>
            </div>
            <div
              className="w-100 mt-3"
              style={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                borderRadius: "15px",
                margin: "auto",
                boxShadow: "20px 20px 50px #c2c2c2",
              }}
            >
              <p
                style={{
                  marginTop: "1em",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "0.8em",
                  marginLeft: "2em",
                }}
              >
                12. Est ce que je peux participer à plusieurs campagnes
                différentes en même temps ?
              </p>
              <p
                style={{
                  marginTop: "-1em",
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "0.8em",
                  marginLeft: "3em",
                }}
              >
                Malheureusement il n'est pas possible d'avoir des stickers
                publicitaires de plusieurs marques sur une même voiture.
              </p>
            </div>
          </div>
        </div>

        <div id="QuestionAnswers"></div>
      </div>
    );
  }
}

export default AmbassadorAccueil;

import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { logout } from "../../Redux/actions/auth";
import { connect } from "react-redux";

function DisconnectModal({ logout }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        style={{
          color: "#F7f8f3",
          backgroundColor: "#EB2735",
          borderTopRightRadius: "25px",
          borderBottomRightRadius: "25px",
          borderTopLeftRadius: "25px",
          borderBottomLeftRadius: "25px",
          fontFamily: "'Poppins', sans-serif",
          fontSize: "15px",
          fontWeight: "bold",
          textAlign: "center",
          width: "100%",
          marginTop: "0.2em",
        }}
      >
        Se Déconnecter
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Confirmation de Déconnexion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes vous sùr de vouloir vous Déconnecter ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button
            onClick={() => {
              logout();
            }}
            color="primary"
            autoFocus
          >
            Se Déconnecter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});
export default connect(null, mapDispatchToProps)(DisconnectModal);

import React, { PureComponent } from "react";

import { Link } from "react-router-dom";

export default class Accueil extends PureComponent {
  render() {
    return (
      <div style={{}} className="container   col-md-8 col-lg-8 col-sm-8">
        <div
          className="MarginLeftNone"
          style={{
            marginLeft: "2.5em",
            alignItems: "center",
          }}
        >
          <div
            id="AccueilTitle "
            style={{
              marginTop: "1.2em",
              fontFamily: "Heebo",
              fontSize: "2.6em",
              lineHeight: "73px",
              letterSpacing: "7px",
              color: " #EB2735",
              fontWeight: "900",
              opacity: "1",
            }}
            className="resTextTitle TxtAlignCenter mtNone"
          >
            LA PUB QUI ROULE
          </div>
          <div
            id="AccueilText"
            style={{
              textAlign: "left",
              fontFamily: "Poppins",
              fontSize: "0.95em",
              fontWeight: "610",
              letterSpacing: "0px",
              color: " #012C3D",
              lineHeight: "2.3em",
              width: "50%",
            }}
            className="widthHundred"
          >
            WinWin est une plateforme qui offre une solution innovante de
            publicité mobile et collaborative.
            <br />
            Elle permet aux particuliers de devenir ambassadeurs de leurs
            marques préférées en habillant leurs voitures avec des stickers
            publicitaires proposés par les annonceurs.
          </div>
          <div
            id="AccueilButtons"
            style={{
              marginTop: "2%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              id="AccueilAdv"
              className="WinWinNavButtonWhite mRightNone"
              style={{
                width: "14em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1.8em",
              }}
            >
              <img src="/shout.png" alt="shout" className="w20" />
              <Link
                to="/Advertiser"
                className="nav-link Fsize08em"
                style={{
                  color: "#EB2735",
                  borderColor: "#EB2735",

                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "110%",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                Annonceur
              </Link>
            </div>
            <div
              id="AccueilAmbass"
              className="WinWinNavButtonRED "
              style={{
                width: "14em",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link
                to="/Ambassador"
                className="nav-link Fsize08em"
                style={{
                  color: "#ffffff",
                  borderColor: "#EB2735",

                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "110%",
                  fontWeight: "600",
                  textAlign: "center",
                  zIndex: "2",
                  position: "relative",
                }}
              >
                Ambassadeur
              </Link>
              <img src="/carWelcome.png" alt="carWelcome" className="w20" />
            </div>
          </div>
        </div>
        <img
          className="img-fluid Prelative DNoneImg"
          alt="accueil"
          src="/acceuil.jpg"
          style={{
            zIndex: "-1",
            position: "absolute",
            marginLeft: "16em",
            marginTop: "-16em",
            width: "80%",
          }}
        />
      </div>
    );
  }
}

import React, { Component } from "react";
import Header from "./Components/layout/Header";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
  /* Redirect */
} from "react-router-dom";
import { Provider } from "react-redux";
import { Provider as AlertProvider, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import store from "./Redux/store";
import Dashboard from "./Components/campaign/Dashboard";
/* import { Alerts } from "./Components/layout/Alerts"; */
import Login from "./Components/accounts/Login";
import Register from "./Components/accounts/Register";
import PrivateRoute from "./Components/common/PrivateRoute";
import "bootstrap/dist/css/bootstrap.min.css";
import { loadUser } from "./Redux/actions/auth";
import Contact from "./Components/layout/Contact";
import Accueil from "./Components/layout/Accueil";
import Footer from "./Components/layout/Footer";
import AmbassadorAccueil from "./Components/layout/AmbassadorAccueil";
import AdvertiserAccueil from "./Components/layout/AdvertiserAccueil";
//alert options
const alertOption = {
  timeout: 3000,
  position: "top center",
  containerStyle: { width: "100%" },
  transition: transitions.SCALE,
};
export class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }
  render() {
    return (
      <Provider store={store} className="container">
        <AlertProvider template={AlertTemplate} {...alertOption}>
          <Router>
            <Header className="container w-100" />
            <div
              className="container  w-100 col-md-12 col-lg-12 col-sm-12"
              style={{
                minHeight: "calc(100vh - 70px )",
                paddingBottom: "0px",
              }}
            >
              {/*  <Alerts /> */}

              <Redirect exact from="/" to="/Accueil" />
              <Switch>
                <PrivateRoute exact path="/Dashboard" component={Dashboard} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/Ambassador" component={AmbassadorAccueil} />
                <Route exact path="/Advertiser" component={AdvertiserAccueil} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/Accueil" component={Accueil} />
                <Route exact path="/login" component={Login} />
              </Switch>
            </div>

            <Footer className="container  w-100" />
          </Router>
        </AlertProvider>
      </Provider>
    );
  }
}

export default App;
